import { mdiMenu, mdiArrowLeft } from '@mdi/js';
import { Icon } from '@mdi/react';
import { LoadingButton } from '@mui/lab';
import { AppBar, Box, IconButton, Menu, MenuItem, Select, SelectChangeEvent, Toolbar, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import { LocationModel, LOCATION_INITIAL_STATE } from '../../../models/Location/LocationModel';
import { PassageiroModel, PassageiroSearchModel } from '../../../models/Passageiro/PassageiroModel';
import { usePwaService } from '../../../services/pwa.service';
import { useUsuarioLocalStorageService } from '../../../services/usuario.localstorage.service';
import { AppContainer } from '../../components/AppContainer';
import { HelmetTitle } from '../../components/HelmetTitle';
import { LoadingBackdrop } from '../../components/LoadingBackdrop';
import { ModalImpressao } from '../../components/ModalImpressao/ModalImpressao';
import { PATHS } from '../../routes/paths';
import { GridPassageiros } from './components/GridPassageiros';
import { ModalLeituraCodigoBarras } from './components/ModalLeituraCodigoBarras';
import { useLeituraPassagemController } from './LeituraPassagemController';
import * as S from './styles/index';
import { ModalConfirmarFinalizarEmbarque } from '../embarque-promotor/components/ModalConfirmarFinalizarEmbarque';

type Props = ReturnType<typeof useLeituraPassagemController>;

export const LeituraPassagemView = ({
  state: {
    LOCALIZADOR_ID,
    modalLeituraCodigoDeBarrasAberta,
    embarqueIsloading,
    usuario,
    passageirosSelecionados,
    codigoQrCode,
  },
  alternarLeituraCodigoDeBarras,
  setModalQrCode,
  embarcar,
  finalizar,
  selecionarPassageiros,
  setQRCodeValue,
  pontoEmbarque
}: Props) => {
  const context = useAuth();
  const { listarPassageiros } = usePwaService();
  const navigate = useNavigate();

  const [passageiros, setPassageiros] = useState<PassageiroModel[]>([]);
  const [passageirosFiltrados, setPassageirosFiltrados] = useState<PassageiroModel[]>([]);
  const [location, setLocation] = useState<LocationModel | null>(LOCATION_INITIAL_STATE);
  const [modalImpressao, setModalImpressao] = useState<boolean>(false);
  const [passageirosIsloading, setPassageirosIsloading] = useState<boolean>(false);
  const [sentidoViagem, setSentidoViagem] = useState<string>("");
  const [showModalConfirmacaoFinalizarEmbarque, setShowModalConfirmacaoFinalizarEmbarque] = useState<boolean>(false);
  const [listaDestinos, setListaDestinos] = useState<Array<string>>([]);
  const [destinoSelect, setDestinoSelect] = useState<string>("Todos os destinos");

  const refHiddenLink = useRef<HTMLAnchorElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSair = () => {
    setAnchorEl(null);
    context.Logout();
  };

  const handlePesquisaGeral = () => {
    navigate(PATHS.LeituraGeralPassagem.Principal);
  };

  async function obterPassageiros() {
    try {
      setSentidoViagem(pontoEmbarque?.descricao.split(':')[0])

      const request: PassageiroSearchModel = {
        eventoId: pontoEmbarque?.eventoId,
        cidadeOrigemId: pontoEmbarque?.cidadeOrigemId,
        dataHoraKey: pontoEmbarque?.dataHoraKey,
        motoristaCpf: pontoEmbarque?.motoristaCpf,
      };
      setPassageirosIsloading(true);
      const response = await listarPassageiros(request);
      setPassageiros(response);
      setPassageirosFiltrados(response);

      const arrayDestinos = response.map(objeto => pontoEmbarque.dataHoraKey.includes('R') ? objeto.cidadeOrigemNome : objeto.cidadeDestinoNome)
        .filter((valor, index, self) => self.indexOf(valor) === index);
      arrayDestinos.unshift("Todos os destinos")

      setListaDestinos(arrayDestinos)
    } finally {
      setPassageirosIsloading(false);
    }
  }

  async function embarcarHandler(bagagens?: string[]): Promise<void> {
    let isMultiPassageiros = passageirosSelecionados && passageirosSelecionados.length > 1 ? true : false;
    await embarcar(location!, isMultiPassageiros, bagagens);
    obterPassageiros();
  }

  function abrirModalImpressao() {
    setModalImpressao(true);
  }

  function handleFiltrarPorDestino(destino: string) {
    if (destino === "" || destino == 'Todos os destinos') {
      setPassageirosFiltrados(passageiros)
      setDestinoSelect("Todos os destinos")
    } else {
      let passageirosFilter: PassageiroModel[] = passageiros.filter(passageiro => (pontoEmbarque.dataHoraKey.includes('R') ? passageiro.cidadeOrigemNome : passageiro.cidadeDestinoNome) === destino)
      setPassageirosFiltrados(passageirosFilter)
      setDestinoSelect(destino);
    }
  }

  async function handleFinalizarEmbarque() {
    await finalizar();
    refHiddenLink.current?.click();
    setTimeout(()=>{
      navigate(PATHS.SelecaoPontoEmbarque.Principal);
    }, 500);
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
    });
    obterPassageiros();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HelmetTitle subtitulo="Busca de passageiro" />

      <AppBar position="sticky" color="secondary">
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleClick}>
            <Icon path={mdiMenu} size={1} />
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <MenuItem onClick={handleCloseSair}>Sair</MenuItem>
            <MenuItem onClick={handlePesquisaGeral}>Pesquisa geral</MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Busca de passageiro
          </Typography>
        </Toolbar>
      </AppBar>

      <AppContainer>
        <Box sx={{ display: 'flex', mt: 1 }}>
          <S.InfoPontoEmbarque>{pontoEmbarque?.descricao}</S.InfoPontoEmbarque>
        </Box>

        <Box marginTop={'15px'} display={'flex'} justifyContent={'space-between'}>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => navigate(PATHS.Home)}>
            <Icon path={mdiArrowLeft} size={1} /> <span className='font-15'>Voltar</span>
          </IconButton>
          {
            sentidoViagem === 'VOLTA' &&
            <Select
              sx={{ width: '50%' }}
              fullWidth
              id="destino"
              onChange={(event: SelectChangeEvent<string>) => {
                handleFiltrarPorDestino(event.target.value)
              }}
              defaultValue='0'
              value={destinoSelect}>
              {listaDestinos.map((item, index) => (
                <MenuItem key={`item_${index}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          }
        </Box>

        <div>
          <GridPassageiros
            defaultList={passageirosFiltrados}
            isMultiPassageiros={passageirosSelecionados && passageirosSelecionados.length > 1 ? true : false}
            selecionarPassageiros={selecionarPassageiros}
            embarcar={embarcarHandler}
            ehMotorista={true}
            setModalQrCode={setModalQrCode}
            abrirModalImpressao={abrirModalImpressao}
            codigoQrCode={codigoQrCode}
            setQRCodeValue={setQRCodeValue}
            usarKeyR={pontoEmbarque?.dataHoraKey?.includes('R')}
            motoristaCpf={pontoEmbarque?.motoristaCpf ?? ""}
          />
          <a id='motoraLink' ref={refHiddenLink} href={`rsp://motora.ai/end-travel`}></a>
        </div>
        {embarqueIsloading && <LoadingBackdrop open title="Carregando..." />}
        {passageirosIsloading && <LoadingBackdrop open title="Carregando..." />}

        <Box sx={{ display: 'flex', mt: 1 }}>
          <LoadingButton
            color="error"
            loading={false}
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 1, mb: 2 }}
            onClick={() => setShowModalConfirmacaoFinalizarEmbarque(true)}>
            Finalizar
          </LoadingButton>
        </Box>
      </AppContainer>

      <ModalConfirmarFinalizarEmbarque
        aberto={showModalConfirmacaoFinalizarEmbarque}
        fechar={() => setShowModalConfirmacaoFinalizarEmbarque(false)}
        finalizarEmbarque={()=> handleFinalizarEmbarque()}
      />

      <ModalLeituraCodigoBarras
        aberto={modalLeituraCodigoDeBarrasAberta}
        fechar={setModalQrCode}
        confirmarLeitura={alternarLeituraCodigoDeBarras}
      />
      <ModalImpressao
        passageirosSelecionados={passageirosSelecionados?.length ? passageirosSelecionados : passageiros}
        aberto={modalImpressao}
        fechar={() => {
          setModalImpressao(false);
        }}
      />
    </>
  );
};
