import { useApi } from '../hooks/api';
import { ParamsEnviarMensagemMotorista, ParamsObterChatMotorista, RetornoObterChat, RetornoObterContatos } from '../models/Chat';
import { LoginRequest } from '../models/Login/LoginRequestModel';
import { EmbarcarPassageiroModel, EmbarcarPassageirosMultiplosModel, EmbarcarPassageirosMultiplosPromotorModel, PassageiroModel, PassageiroSearchModel, TodosPassageiroSearchModel } from '../models/Passageiro/PassageiroModel';
import { EmbarqueModel, PontoEmbarqueModel } from '../models/PontoEmbarque/PontoEmbarqueModel';

export function usePwaService() {
  const { post, put } = useApi();
  const baseApi = `/Pwa`;

  async function validarLogin(request: LoginRequest) {
    const { data: response } = await post(`${baseApi}/validar-login`, request);
    return response;
  }

  async function obterPontosDeEmbarque(request: LoginRequest) {
    const result = await post<PontoEmbarqueModel[]>(`${baseApi}/listar-pontos-embarque`, request);
    return result;
  }

  async function iniciarEmbarque(request: EmbarqueModel) {
    const { data: response } = await put(`${baseApi}/iniciar-embarque`, request);
    return response;
  }

  async function listarPassageiros(request: PassageiroSearchModel) {
    const { data: response } = await post<PassageiroModel[]>(`${baseApi}/listar-passageiros`,request);
    return response;
  }

  async function embarcarPassageiro(request: EmbarcarPassageiroModel) {
    const { data: response } = await put(`${baseApi}/embarcar-passageiro`, request);
    return response;
  }

  async function embarcarPassageirosMultiplos(request: EmbarcarPassageirosMultiplosModel) {
    const { data: response } = await put(`${baseApi}/embarcar-passageiros-multiplos`, request);
    return response;
  }

  async function embarcarPassageirosMultiplosPromotor(request: EmbarcarPassageirosMultiplosPromotorModel) {
    const { data: response } = await put(`${baseApi}/embarcar-passageiros-multiplos-promotor`, request);
    return response;
  }

  async function finalizarEmbarque(request: EmbarqueModel) {
    const { data: response } = await put(`${baseApi}/finalizar-embarque`, request);
    return response;
  }

  async function embarqueEmAndamento(request: LoginRequest) {
    const { data: response } = await put(`${baseApi}/embarque-em-andamento`, request);
    return response;
  }

  async function listarTodosPassageiros(request: TodosPassageiroSearchModel) {
    const { data: response } = await post<PassageiroModel[]>(`${baseApi}/listar-todos-passageiros`,request);
    return response;
  }

  async function obterContatosChatMotorista(motoristaCpf: string) {
    const { data: response } = await post<RetornoObterContatos>(`${baseApi}/obter-contatos-motorista`, {motoristaCpf});
    return response;
  }

  const obterChatMotorista = async (params: ParamsObterChatMotorista) => {
    return await post<RetornoObterChat>(`${baseApi}/obter-chat-motorista`, params);
  };

  const enviarMensagemMotorista = async (params: ParamsEnviarMensagemMotorista) => {
    return await post<RetornoObterChat>(`${baseApi}/enviar-mensagem-motorista`, params);
  };

  return {
    validarLogin,
    obterPontosDeEmbarque,
    iniciarEmbarque,
    listarPassageiros,
    embarcarPassageiro,
    embarcarPassageirosMultiplos,
    embarcarPassageirosMultiplosPromotor,
    finalizarEmbarque,
    embarqueEmAndamento,
    listarTodosPassageiros,
    obterContatosChatMotorista,
    obterChatMotorista,
    enviarMensagemMotorista,
  };
}
