import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { FlexBoxColumn } from '../FlexBoxColumn';
import { ReactNode } from 'react';
import { BackdropDrawer, DrawerStyle, TituloDrawer } from './styled';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

type Props = {
  showDrawer: boolean;
  onClose: () => void;
  children: ReactNode
};

export const Drawer = ({ showDrawer, onClose, children }: Props) => {
  return (
    <BackdropDrawer show={showDrawer}>
        <DrawerStyle showDrawer={showDrawer}>
        <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
                <TituloDrawer>Comunicação</TituloDrawer>
                <IconButton aria-label="delete" size="small" onClick={onClose}>
                    <Icon path={mdiClose} size={1} />
                </IconButton>
            </Grid>

            {children}
        </Grid>
        </DrawerStyle>
    </BackdropDrawer>
  );
};
