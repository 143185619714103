import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const DivPrintContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconButtonCustom = styled(IconButton)`
  position: absolute !important;
  right: 1em;
`;
