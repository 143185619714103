import { PassagemModel } from '../../models/Passagem/PassagemModel';
import { PontoEmbarqueModel } from '../../models/PontoEmbarque/PontoEmbarqueModel';

export const PASSAGEM_INITIAL_STATE: SquadAppState = {
  pontoEmbarque: undefined,
};

interface SquadAppState {
  pontoEmbarque: PontoEmbarqueModel | undefined;
}
