export interface ResponseIniciarEmbarque {
    nomePontoEmbarque: string;
    nomePromotor: string;
    sentidoViagem: string;
    nomeDestino: string[]
    horarios: string[];
    viagens: ViagensPromotor[]
}

interface ViagensPromotor {   
    eventId: number,
    tripId: number,
    originCityID: number,
    routeId: number,
    dataEvento: string,
    eventoNome: string,
    cidadeOrigemNome: string,
    horaPartida: string,
    motoristaCpf: string,
    dataHoraKey: string
}

export interface Horario {
    horarioOriginal: string;
    horarioFormatado: string;
}

export interface InformacoesEmbarque {
    nomePontoEmbarque: string;
    nomePromotor: string;
    sentidoViagem: string;
    nomeDestino: string[]
    quantidadePassageiros: number;
    quantidadePassageirosFiltro: number;
    quantidadePassageirosEmbarcados: number;
    porcentagemPassageirosEmbarcados?: number;
}

export const INFOS_EMBARQUE_INITIAL_STATE: InformacoesEmbarque = {
    nomePontoEmbarque: "",
    nomePromotor: "",
    sentidoViagem: "",
    nomeDestino: [],
    quantidadePassageiros: 0,
    quantidadePassageirosFiltro: 0,
    quantidadePassageirosEmbarcados: 0,
    porcentagemPassageirosEmbarcados: 0
};