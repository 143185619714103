import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { LoginRequest } from '../models/Login/LoginRequestModel';
import { usePwaService } from '../services/pwa.service';
import { useUsuarioLocalStorageService } from '../services/usuario.localstorage.service';
import { createBrowserHistory } from 'history';
import { useAppDispatch } from '../store/hooks';
import { SquadAppActions } from '../store/squadApp/passagemActions';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { salvarDadosCache, obterUsuario, salvarPontoEmbarque } = useUsuarioLocalStorageService();
  const { validarLogin } = usePwaService();
  const dispatch = useAppDispatch();

  const usuarioLogado = obterUsuario();
  const [user, setUser] = useState<LoginRequest | null>(usuarioLogado);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let browserHistory = createBrowserHistory();

  async function Login(data: LoginRequest) {
    try {
      setIsLoading(true);
      await validarLogin(data);
      salvarDadosCache(data);
      setUser(data);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }

  function Logout() {
    dispatch(SquadAppActions.armazenarPontoEmbarque({ pontoEmbarque: undefined }));
    browserHistory.replace('/');
    salvarDadosCache(null);
    salvarPontoEmbarque(null);
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: verificarUsuario(user),
        user,
        Login,
        Logout,
        isLoading,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;

function verificarUsuario(user: LoginRequest | null): boolean {
  return Boolean(user);
}
interface AuthContextData {
  signed: boolean;
  user: LoginRequest | null;
  Login(data: LoginRequest): Promise<void>;
  Logout(): void;
  isLoading: boolean;
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
