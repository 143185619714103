import { useContext } from 'react';
import { BrowserRouter, useParams } from 'react-router-dom';
import AuthContext, { useAuth } from '../../contexts/auth';
import { HelmetTitle } from '../components/HelmetTitle';
import { Login } from '../pages/login/Login';
import { AppRoutes } from '../routes';
import { EmbarquePromotor } from '../pages/embarque-promotor';

function App() {
  const { signed } = useContext(AuthContext);
  const context = useAuth();
  const isEmbarquePromotor = window.location.pathname.includes('embarque-promotor')

  return (
    <>
      <HelmetTitle />
      <BrowserRouter>
        {
          !context.isLoading && signed ? (
            <AppRoutes />
          ) : (
            <>
              {
                isEmbarquePromotor ? (
                  <EmbarquePromotor/>
                ) : (
                  <Login />
                )
              }
            </>
          )
        }
      </BrowserRouter>
    </>
  );
}

export default App;