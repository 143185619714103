import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from '../../store/store';
import { COLORS } from '../../utils/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
  },
});

export const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <HelmetProvider>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={4000}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Provider store={store}>{children}</Provider>
        </ThemeProvider>
      </SnackbarProvider>
    </HelmetProvider>
  );
};

