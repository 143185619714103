import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PontoEmbarqueModel } from '../../models/PontoEmbarque/PontoEmbarqueModel';
import { PASSAGEM_INITIAL_STATE } from './passagemState';

export const squadAppSlice = createSlice({
  name: 'pontoEmbarque',
  initialState: PASSAGEM_INITIAL_STATE,
  reducers: {
    armazenarPontoEmbarque: (state, action: PayloadAction<{ pontoEmbarque: PontoEmbarqueModel | undefined}>) => {
      state.pontoEmbarque = action.payload.pontoEmbarque;
    },
    limpar: (state) => {
      state.pontoEmbarque = undefined;
    },
  },
});
