import { useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { FlexBoxColumn } from '../../../components/FlexBoxColumn';
import { COLORS } from '../../../../utils/colors';
import { mdiClose, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { useSnackbar } from 'notistack';

type Props = {
  aberto: boolean;
  fechar: () => void;
  embarcar: (bagagens: string[]) => void;
};

export const ModalInformarBagagens = ({ aberto, embarcar, fechar }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [bagagens, setBagagens] = useState<string[]>([])
  const [inputBagageValue, setInputBagageValue] = useState<string>("")

  function addBagage() {
    if (inputBagageValue !== '') {
      let arr = bagagens
      let existsBagage = arr.some(bag => bag === inputBagageValue)

      if (existsBagage) {
        enqueueSnackbar('Esta bagagem já foi adicionada!', { variant: 'warning' });
        return
      } else {
        arr.push(inputBagageValue)
        setBagagens(arr)
        setInputBagageValue('')
      }
    }
  }

  function removeBagage(bagage: string) {
    let arr = bagagens
    arr = arr.filter(bag => bag !== bagage)
    setBagagens(arr)
  }

  function onClose() {
    setBagagens([])
    setInputBagageValue('')
    fechar()
  }

  return (
    <Dialog fullWidth maxWidth="md" open={aberto} onClose={onClose}>
      <DialogTitle>Informar bagagens</DialogTitle>
      <DialogContent>
        <FlexBoxColumn>
          <Box sx={{ display: 'flex', gap: '10px', mt: 1 }}>
            <TextField
              required
              fullWidth
              name="bagagem"
              type="text"
              id="bagagem"
              placeholder='Informe a bagagem'
              value={inputBagageValue}
              onChange={(e) => setInputBagageValue(e.target.value)}
            />
            <Button
              color="info"
              variant="contained"
              onClick={addBagage}
            >
              <Icon path={mdiPlus} size={1.7} style={{ marginRight: '7px' }} /> Adicionar
            </Button>
          </Box>
        </FlexBoxColumn>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', mt: 3, mb: 3 }}>
          {
            bagagens.length > 0 && bagagens.map((bagage) => (
              <Chip label={bagage} onDelete={() => removeBagage(bagage)} />
            ))
          }
        </Box>
      </DialogContent>

      <DialogActions>
        <Button fullWidth sx={{ mx: 2, mb: 1 }} onClick={fechar}>
          Cancelar
        </Button>
        <Button
          color="secondary"
          variant="contained"
          fullWidth sx={{ mx: 2, mb: 1 }}
          onClick={() => {
            embarcar(bagagens)
            onClose()
          }}
        >
          {bagagens.length > 0 ? 'Embarcar' : 'Embarcar sem bagagens'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
