import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { LocationModel } from '../../../models/Location/LocationModel';
import { EmbarcarPassageiroModel, EmbarcarPassageirosMultiplosModel, PassageiroModel } from '../../../models/Passageiro/PassageiroModel';
import { usePwaService } from '../../../services/pwa.service';
import { useUsuarioLocalStorageService } from '../../../services/usuario.localstorage.service';
import { usePromotorService } from '../../../services/promotor.service';
import { useParams } from 'react-router-dom';
import { NotistackActions } from './components/NotistackActions';

const LOCALIZADOR_ID = 'localizador';

export const useEmbarquePromotorController = () => {
  const [modalLeituraCodigoDeBarrasAberta, setModalLeituraCodigoDeBarrasAberta] = useState(false);
  const [embarqueIsloading, setEmbarqueIsloading] = useState<boolean>(false);
  const [passageirosSelecionados, setPassageirosSelecionados] = useState<PassageiroModel[] | null>(null);
  const [codigoQrCode, setCodigoQrCode] = useState<string>('');
  const { embarcarPassageiro, embarcarPassageirosMultiplosPromotor } = usePwaService();
  const { finalizarEmbarquePromotor } = usePromotorService();
  const { obterUsuario } = useUsuarioLocalStorageService();
  const usuario = obterUsuario();
  const { enqueueSnackbar } = useSnackbar();

  async function alternarLeituraCodigoDeBarras(termo: string) {
    setQRCodeValue(termo);
    setModalLeituraCodigoDeBarrasAberta(!modalLeituraCodigoDeBarrasAberta);
  }

  function setQRCodeValue(newValue: string) {
    setCodigoQrCode(newValue);
  }

  function selecionarPassageiros(newValue: PassageiroModel[] | null) {
    setPassageirosSelecionados(newValue);
  }

  async function setModalQrCode() {
    setModalLeituraCodigoDeBarrasAberta(!modalLeituraCodigoDeBarrasAberta);
  }

  async function embarcar(location: LocationModel, isMultiPassageiros: boolean, bagagens?: string[], sentido?: string): Promise<number[]> {
    try {
      if (isMultiPassageiros) {
        let passageirosParaEmbarcar: Array<EmbarcarPassageiroModel> = []
        
        passageirosSelecionados && passageirosSelecionados.map((passageiro) => {
          let obj = {
            eventoId: passageiro.eventoId,
            viagemId: passageiro.viagemId,
            cidadeOrigemId: sentido == 'IDA' ? passageiro.cidadeOrigemId : passageiro.cidadeDestinoId ,
            dataHoraKey: sentido == 'IDA' ? passageiro.dataHoraKey : passageiro.dataHoraKeyR,
            latitude: location.latitude,
            longitude: location.longitude,
            motoristaCpf: passageiro.motoristaCpf.toString(),
            passageiroId: passageiro.passageiroId,
            passageiroNome: passageiro.nome,
          }

          passageirosParaEmbarcar.push(obj)
        })

        let error = false;
        let erros: any = {}
        const response: any = await embarcarPassageirosMultiplosPromotor({passageirosEmbarcados: passageirosParaEmbarcar});

        for(const resultado of response?.resultados??[]){
            if(!resultado.sucesso){
              if(!erros[resultado.mensagem]){
                erros[resultado.mensagem] = []
              }
              erros[resultado.mensagem].push(`${resultado.passageiro} (${resultado.numeroEmbarque})`)
              error = true;
            }
        }
        if(error){
          for(let mensagem in erros){
            let errorStr = `Erro na sympla: ${mensagem}     `;
            errorStr += erros[mensagem].join(";  ")  
            enqueueSnackbar(errorStr, { variant: 'error', persist: true,  action: NotistackActions });
          }
          enqueueSnackbar('Passageiros embarcados, mas checkout na sympla não realizado!', { variant: 'warning' });
        }
        else
          enqueueSnackbar('Passageiros embarcados com sucesso!', { variant: 'success' });

        setPassageirosSelecionados(null);
        return passageirosParaEmbarcar.map(x => x.passageiroId);
      } else {
        const request: EmbarcarPassageiroModel = {
          eventoId: passageirosSelecionados![0].eventoId,
          viagemId: passageirosSelecionados![0].viagemId,
          cidadeOrigemId: sentido == 'IDA' ? passageirosSelecionados![0].cidadeOrigemId : passageirosSelecionados![0].cidadeDestinoId,
          dataHoraKey: sentido == 'IDA' ? passageirosSelecionados![0].dataHoraKey : passageirosSelecionados![0].dataHoraKeyR,
          latitude: location.latitude,
          longitude: location.longitude,
          motoristaCpf: passageirosSelecionados![0].motoristaCpf.toString(),
          passageiroId: passageirosSelecionados![0].passageiroId,
          passageiroNome: passageirosSelecionados![0].nome,
          bagagens: bagagens,
        };
        setEmbarqueIsloading(true);

        let error = false;
        let erros: any = {}
        const response: any = await embarcarPassageiro(request);

        for(const resultado of response?.resultados??[]){
          if(!resultado.sucesso){
            if(!erros[resultado.mensagem]){
              erros[resultado.mensagem] = []
            }
            erros[resultado.mensagem].push(`${resultado.passageiro} (${resultado.numeroEmbarque})`)
            error = true;
          }
        }
        if(error){
          for(let mensagem in erros){
            let errorStr = `Erro na sympla: ${mensagem}     `;
            errorStr += erros[mensagem].join(";  ")  
            enqueueSnackbar(errorStr, { variant: 'error', persist: true,  action: NotistackActions });
          }
          enqueueSnackbar('Passageiro embarcado, mas checkout na sympla não realizado!', { variant: 'warning' });
        }
        else
          enqueueSnackbar('Passageiro embarcado com sucesso!', { variant: 'success' });
        
        setPassageirosSelecionados(null);
        return [passageirosSelecionados![0].passageiroId];
      }

    } finally {
      setEmbarqueIsloading(false);
    }
  }

  async function finalizarEmbarque(codigo?: string): Promise<void> {
    try {
      setEmbarqueIsloading(true);
      await finalizarEmbarquePromotor(codigo);
      enqueueSnackbar('Embarque finalizado!', { variant: 'success' });
      setPassageirosSelecionados(null);
    } finally {
      setEmbarqueIsloading(false);
    }
  }

  return {
    state: {
      LOCALIZADOR_ID,
      embarqueIsloading,
      modalLeituraCodigoDeBarrasAberta,
      usuario,
      passageirosSelecionados,
      codigoQrCode,
    },
    alternarLeituraCodigoDeBarras,
    setModalQrCode,
    embarcar,
    finalizarEmbarque,
    selecionarPassageiros,
    setQRCodeValue
  };
};
