import { Button, CircularProgress, Grid, TextareaAutosize, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { BoxListaMensagens, BoxMensagem, Textarea } from "../../../components/Drawer/styled";
import { usePromotorService } from "../../../../services/promotor.service";
import { MensagemChatCondensadaDto, ParamsEnviarMensagemPromotor, ParamsObterChatPromotor, RetornoObterChat } from "../../../../models/Chat";
import Icon from "@mdi/react";
import { mdiSend } from "@mdi/js";

interface Props {
  idConversa: string
  linkPromotoria: string
}
  
export default function ConversaPromotor({ idConversa, linkPromotoria }: Props) {
  const { obterChatPromotor, enviarMensagemPromotor } = usePromotorService();

  const [loading, setLoading] = useState<boolean>(true);
  const [chamarFuncao, setChamarFuncao] = useState<boolean>(true);
  const [mensagens, setMensagens] = useState<MensagemChatCondensadaDto[]>([]);
  const [ultimoTimeStamp, setUltimoTimeStamp] = useState<string | null>(null);
  const [mensagemDigitada, setMensagemDigitada] = useState<string>("");

  async function handleEnviarMensagem() {
    let obj: ParamsEnviarMensagemPromotor = {
      mensagem: mensagemDigitada,
      idDestinatario: idConversa,
      linkPromotoria: linkPromotoria
    }

    let result = await enviarMensagemPromotor(obj) as unknown as RetornoObterChat
    setMensagens(result?.data?.mensagens)
    setUltimoTimeStamp(result?.data?.timestampUltimaMensagem)
    setMensagemDigitada("")

  }

  function obterConversa() {
    let obj: ParamsObterChatPromotor = {
      idDestinatario: idConversa,
      linkPromotoria: linkPromotoria
    }

    obterChatPromotor(obj).then((response: any) => {
      if (response?.data?.timestampUltimaMensagem !== ultimoTimeStamp) {
        setMensagens(response?.data?.mensagens)
      }

      setUltimoTimeStamp(response?.data?.timestampUltimaMensagem)
      setChamarFuncao(prevState => !prevState)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setTimeout(() => {
      obterConversa()
    }, 20000)
  }, [chamarFuncao])

  useEffect(() => {
    obterConversa()
  }, [idConversa])

  return (
    <>
      <BoxListaMensagens>
        {
          loading ? (
            <CircularProgress />
          ) : (
            <>
              {
                mensagens && mensagens.length > 0 && mensagens.map((mensagem) => (
                  <Grid item display={'flex'} justifyContent={mensagem.enviada ? 'end' : 'start'}>
                    <BoxMensagem enviado={mensagem.enviada}>
                      {mensagem.conteudo}
                    </BoxMensagem>
                  </Grid>
                ))
              }
            </>
          )
        }
      </BoxListaMensagens>

      <Textarea
        minRows={4}
        maxRows={4}
        aria-label="maximum height"
        placeholder="Faça uma descrição atrativa, que desperte a vontade de viajar em nossos clientes!"
        defaultValue=""
        value={mensagemDigitada}
        onChange={(e: any) => setMensagemDigitada(e.target.value)}
      />

      <Button
        type="button"
        fullWidth
        color="secondary"
        variant="contained"
        sx={{ mt: 2 }}
        onClick={handleEnviarMensagem}
        disabled={loading || mensagemDigitada == ""}
      >
        Enviar mensagem <Icon path={mdiSend} size={1} />
      </Button>
    </>
  )
}