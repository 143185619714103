import { useApi } from '../hooks/api';
import { ParamsEnviarMensagemPromotor, ParamsObterChatPromotor, RetornoObterChat, RetornoObterContatos } from '../models/Chat';
import { PassageiroModel } from '../models/Passageiro/PassageiroModel';
import { ResponseIniciarEmbarque } from '../models/Promotor';

export function usePromotorService() {
  const { post, put } = useApi();
  const baseApi = `/Pwa`;

  async function iniciarEmbarquePromotor(codigo?: string) {
    const { data: response } = await put<ResponseIniciarEmbarque>(`${baseApi}/iniciar-embarque-promotor/${codigo}`);
    return response;
  }

  async function obterPassageirosPromotor(codigo?: string) {
    const { data: response } = await post<PassageiroModel[]>(`${baseApi}/listar-passageiros-promotor/${codigo}`);
    return response;
  }

  async function finalizarEmbarquePromotor(codigo?: string) {
    const { data: response } = await put(`${baseApi}/finalizar-embarque-promotor/${codigo}`);
    return response;
  }

  async function obterContatosChatPromotor(linkPromotoria: string) {
    const { data: response } = await post<RetornoObterContatos>(`${baseApi}/obter-contatos-promotor`, {linkPromotoria});
    return response;
  }

  const obterChatPromotor = async (params: ParamsObterChatPromotor) => {
    return await post<RetornoObterChat>(`${baseApi}/obter-chat-promotor`, params);
  };

  const enviarMensagemPromotor = async (params: ParamsEnviarMensagemPromotor) => {
    return await post<RetornoObterChat>(`${baseApi}/enviar-mensagem-promotor`, params);
  };

  return {
      obterPassageirosPromotor,
      iniciarEmbarquePromotor,
      finalizarEmbarquePromotor,
      obterContatosChatPromotor,
      obterChatPromotor,
      enviarMensagemPromotor,
  };
}
