import { mdiArrowLeftCircle, mdiBarcodeScan, mdiMenu, mdiQrcodeScan } from '@mdi/js';
import { Icon } from '@mdi/react';
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useAuth } from '../../../contexts/auth';
import { PassageiroModel } from '../../../models/Passageiro/PassageiroModel';
import { usePwaService } from '../../../services/pwa.service';
import { COLORS } from '../../../utils/colors';
import { AppContainer } from '../../components/AppContainer';
import { HelmetTitle } from '../../components/HelmetTitle';
import { LoadingBackdrop } from '../../components/LoadingBackdrop';
import { DadosPassageiro } from '../leitura-passagem/components/DadosPassagem';
import { ModalLeituraCodigoBarras } from '../leitura-passagem/components/ModalLeituraCodigoBarras';
import { useLeituraGeralPassagemController } from './LeituraGeralPassagemController';
import { PATHS } from '../../routes/paths';

type Props = ReturnType<typeof useLeituraGeralPassagemController>;

export const LeituraGeralPassagemView = ({
  state: { LOCALIZADOR_ID, modalLeituraCodigoDeBarrasAberta, embarqueIsloading, passageiroSelecionado, codigoQrCode },
  alternarLeituraCodigoDeBarras,
  setModalQrCode,
  alterarPassageiro,
}: Props) => {
  const context = useAuth();
  const navigate = useNavigate();
  const { listarTodosPassageiros } = usePwaService();

  const [opcoes, setOpcoes] = useState<PassageiroModel[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSair = () => {
    setAnchorEl(null);
    context.Logout();
  };

  function limparInput() {
    setOpcoes([]);
  }

  async function filtrarPassageiros(termoValue: string) {
    if (termoValue) {
      const passageiros = await listarTodosPassageiros({ termo: termoValue });
      setOpcoes(passageiros);
    }
  }

  function filtrarPassageirosHandler(newValue: string) {
    filtrarPassageiros(newValue);
  }

  const changeHandler = useCallback((value: string) => {
    filtrarPassageirosHandler(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  return (
    <>
      <HelmetTitle subtitulo="Busca de passageiro" />

      <AppBar position="sticky" color="secondary">
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleClick}>
            <Icon path={mdiMenu} size={1} />
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <MenuItem onClick={handleCloseSair}>Sair</MenuItem>
            <MenuItem onClick={() => navigate(PATHS.SelecaoPontoEmbarque.Principal)}>Seleção de embarque</MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Busca de passageiro
          </Typography>
        </Toolbar>
      </AppBar>

      <AppContainer>
        <Box sx={{ mt: 2 }}>
          <InputLabel htmlFor={LOCALIZADOR_ID}>Pesquisar passageiro:</InputLabel>

          <Box sx={{ display: 'flex', mt: 1, gap: '5px' }}>
            <Autocomplete
              clearOnBlur
              fullWidth
              options={opcoes ? opcoes : []}
              getOptionLabel={(option) =>
                typeof option === 'object' ? `${option.numeroBilhete} - ${option.nome} - ${option.destino}` : ''
              }
              value={passageiroSelecionado}
              onChange={(event: React.ChangeEvent<{}>, newValue: PassageiroModel | any) => {
                alterarPassageiro(newValue);
              }}
              onInputChange={(_e, value) => debouncedChangeHandler(value)}
              renderInput={(params) => (
                <TextField {...params} label="Passageiro" placeholder="Selecione um passageiro" variant="outlined" />
              )}
              freeSolo
            />
            <Button onClick={setModalQrCode} className='scan-qrcode-button'>
              <Icon path={mdiQrcodeScan} size={1} color={COLORS.primary} style={{ marginRight: '8px' }} /> QR Code
            </Button>
          </Box>
        </Box>

        {embarqueIsloading && <LoadingBackdrop open title="Carregando..." />}
        {typeof passageiroSelecionado === 'object' && passageiroSelecionado && (
          <DadosPassageiro
            data={passageiroSelecionado}
            alterarPassageiro={alterarPassageiro}
            limparInput={limparInput}
          />
        )}
      </AppContainer>

      <ModalLeituraCodigoBarras
        aberto={modalLeituraCodigoDeBarrasAberta}
        fechar={setModalQrCode}
        confirmarLeitura={alternarLeituraCodigoDeBarras}
      />
    </>
  );
};
