import React from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  subtitulo?: string;
};

export const HelmetTitle = ({ subtitulo }: Props) => {
  let titulo = 'Squad app';

  if (subtitulo) {
    titulo = `${subtitulo} - Squad app`;
  }

  return (
    <Helmet>
      <title>{titulo}</title>
    </Helmet>
  );
};

