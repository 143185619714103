import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocationModel } from '../../../models/Location/LocationModel';
import { EmbarcarPassageiroModel, PassageiroModel } from '../../../models/Passageiro/PassageiroModel';
import { EmbarqueModel } from '../../../models/PontoEmbarque/PontoEmbarqueModel';
import { usePwaService } from '../../../services/pwa.service';
import { useUsuarioLocalStorageService } from '../../../services/usuario.localstorage.service';
import { PATHS } from '../../routes/paths';

const LOCALIZADOR_ID = 'localizador';

export const useLeituraGeralPassagemController = () => {
  const [modalLeituraCodigoDeBarrasAberta, setModalLeituraCodigoDeBarrasAberta] = useState(false);
  const [embarqueIsloading, setEmbarqueIsloading] = useState<boolean>(false);
  const [passageiroSelecionado, setPassageiroSelecionado] = useState<PassageiroModel | null>(null);
  const [passageirosSelecionados, setPassageirosSelecionados] = useState<PassageiroModel[] | null>(null);
  const [codigoQrCode, setCodigoQrCode] = useState<string>('');
  const { embarcarPassageiro, finalizarEmbarque, listarTodosPassageiros } = usePwaService();
  const { obterUsuario, obterPontoEmbarque } = useUsuarioLocalStorageService();
  const usuario = obterUsuario();
  const pontoEmbarque = obterPontoEmbarque();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  async function alternarLeituraCodigoDeBarras(termoValue: string) {
    setCodigoQrCode(termoValue);
    const passageiros = await listarTodosPassageiros({ termo: termoValue });
    setPassageiroSelecionado(passageiros[0])
    setModalLeituraCodigoDeBarrasAberta(!modalLeituraCodigoDeBarrasAberta);
  }

  function alterarPassageiro(newValue: PassageiroModel | null) {
    setPassageiroSelecionado(newValue);
  }

  function selecionarPassageiros(newValue: PassageiroModel[] | null) {
    setPassageirosSelecionados(newValue);
  }

  async function setModalQrCode() {
    setModalLeituraCodigoDeBarrasAberta(!modalLeituraCodigoDeBarrasAberta);
  }

  async function embarcar(location: LocationModel): Promise<void> {
    try {
      const request: EmbarcarPassageiroModel = {
        eventoId: pontoEmbarque!.eventoId,
        cidadeOrigemId: pontoEmbarque!.cidadeOrigemId,
        dataHoraKey: pontoEmbarque!.dataHoraKey,
        latitude: location.latitude,
        longitude: location.longitude,
        motoristaCpf: usuario.motoristaCpf.toString(),
        passageiroId: passageirosSelecionados![0].passageiroId,
        passageiroNome: passageirosSelecionados![0].nome,
        viagemId: passageirosSelecionados![0].viagemId,
      };
      setEmbarqueIsloading(true);
      await embarcarPassageiro(request);
      enqueueSnackbar('Passageiro embarcado!', { variant: 'success' });
      setPassageiroSelecionado(null);
    } finally {
      setEmbarqueIsloading(false);
    }
  }

  async function finalizar(): Promise<void> {
    try {
      const request: EmbarqueModel = {
        motoristaCpf: usuario.motoristaCpf.toString(),
        eventoId: pontoEmbarque!.eventoId,
        cidadeOrigemId: pontoEmbarque!.cidadeOrigemId,
        dataHoraKey: pontoEmbarque!.dataHoraKey,
      };
      setEmbarqueIsloading(true);
      await finalizarEmbarque(request);
      enqueueSnackbar('Embarque finalizado!', { variant: 'success' });
      setPassageiroSelecionado(null);
      navigate(PATHS.SelecaoPontoEmbarque.Principal);
    } finally {
      setEmbarqueIsloading(false);
    }
  }

  return {
    state: {
      LOCALIZADOR_ID,
      embarqueIsloading,
      passageiroSelecionado,
      modalLeituraCodigoDeBarrasAberta,
      usuario,
      passageirosSelecionados,
      codigoQrCode,
    },
    alternarLeituraCodigoDeBarras,
    setModalQrCode,
    embarcar,
    finalizar,
    alterarPassageiro,
    selecionarPassageiros,
  };
};
