import { useUsuarioLocalStorageService } from '../../../services/usuario.localstorage.service';
import { LeituraPassagem } from '../leitura-passagem';
import { SelecaoPontoEmbarque } from '../selecao-embarque';

export const Home = () => {
  const { obterUsuario } = useUsuarioLocalStorageService();
  const ehMotorista = obterUsuario()?.ehMotorista;

  return <>{ehMotorista ? <SelecaoPontoEmbarque /> : <LeituraPassagem />}</>;
};
