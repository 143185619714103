import { useSnackbar } from 'notistack';
import { IApiError } from '../utils/types/ApiError';

interface ErrorSnackbarOptions {
  autoThrowError?: boolean;
}

const initOptions: ErrorSnackbarOptions = { autoThrowError: false };

export function useErrorSnackbar({ autoThrowError }: ErrorSnackbarOptions = initOptions) {
  const { enqueueSnackbar } = useSnackbar();

  function tratarErro(err?: IApiError) {
    if (!err || !err?.messages) {
      enqueueSnackbar('Algo deu errado. Tente novamente.', { variant: 'error' });
    } else {
      if (err.messages) {
        err.messages.forEach((element) => {
          enqueueSnackbar(element, {
            variant: 'error',
            preventDuplicate: true,
            autoHideDuration: 5000,
          });
        });
      }
    }

    if (autoThrowError) {
      throw err;
    }
  }
  return { tratarErro };
}
