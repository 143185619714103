import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { Result } from '@zxing/library';
import { FlexBoxColumn } from '../../../components/FlexBoxColumn';

type Props = {
  aberto: boolean;
  fechar: () => void;
  confirmarLeitura: (localizador: string) => void;
};

export const ModalLeituraCodigoBarras = ({ aberto, confirmarLeitura, fechar }: Props) => {
  async function barCodeLido(err: unknown, result: Result | undefined) {
    if (result) {
      const barCode = result.getText();    
      confirmarLeitura(barCode);
    }
  }

  return (
    <Dialog open={aberto} onClose={fechar}>
      <DialogTitle>Leitura de código de barras</DialogTitle>
      <DialogContent>
        <FlexBoxColumn>
          <BarcodeScannerComponent torch={true} onUpdate={barCodeLido} />
        </FlexBoxColumn>
      </DialogContent>

      <DialogActions>
        <Button fullWidth sx={{ mx: 2, mb: 1 }} onClick={fechar}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
