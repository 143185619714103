import { Button, Container, CssBaseline, Grid, Typography } from '@mui/material';
import { PropsWithChildren, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { PassageiroModel } from '../../../../models/Passageiro/PassageiroModel';
import { COLORS } from '../../../../utils/colors';
// import { format } from "date-fns";
// import { ptBR } from "date-fns/locale";

const Titulo = ({ children }: PropsWithChildren) => {
  return (
    <Typography variant="subtitle2" color={COLORS.gray}>
      {children}
    </Typography>
  );
};

type Props = {
  data: PassageiroModel;
  alterarPassageiro: (passageiro: PassageiroModel | null) => void;
  limparInput: () => void;
};

export const DadosPassageiro = ({ data, alterarPassageiro, limparInput }: Props) => {
  const componentRef = useRef<HTMLDivElement>(null);

  let [labelHoraPartida, labelHoraRetorno] = ["",""];
  if((data.horaPartida??"").split(':').length >= 2)
    labelHoraPartida = `${data.horaPartida.split(':')[0]}:${data.horaPartida.split(':')[1]}`
  if((data.horaRetorno??"").split(':').length >= 2)
    labelHoraRetorno = `${data.horaRetorno.split(':')[0]}:${data.horaRetorno.split(':')[1]}`

  return (
    <>
      <CssBaseline />
      <Container
        sx={{ mt: 4, backgroundColor: COLORS.lightGray40, height: '100%', minHeight: '100vh' }}
        maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Titulo>Destino</Titulo>
            <Typography>{data.destino}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Titulo>Origem</Titulo>
            <Typography>{data.cidadeOrigemNome}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Titulo>Nome</Titulo>
            <Typography>{data.nome}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Titulo>Nº bilhete</Titulo>
            <Typography>{data.numeroBilhete}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Titulo>Horário de Partida</Titulo>
            <Typography>{labelHoraPartida}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Titulo>Horário de Retorno</Titulo>
            <Typography>{labelHoraRetorno}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Titulo>Motorista</Titulo>
            <Typography>{data.motorista}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Titulo>Veículo</Titulo>
            <Typography>{data.onibusNumero}</Typography>
          </Grid>

        </Grid>

        <div ref={componentRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , marginTop: "20px"}}>
          <span>Olá {data.nome}</span>
          <span style={{ textAlign: 'center' }}>Tudo está pronto para sua viagem para {data.destino}.</span>
          <img src={data.qrCode} alt="QrCode" style={{ height: '200px', width: '200px' }} />
          <span>N° reserva: #{data.numeroBilhete}</span>
          {data.onibusNumero && (
            <div style={{ textAlign: 'center' }}>
              <span>&nbsp;</span>
              <span style={{ textAlign: 'center' }}>O número do seu ônibus é {data.onibusNumero}</span>
            </div>
          )}
          <span>&nbsp;</span>
          <span style={{ color: 'lightgray' }}>&nbsp;.</span>
        </div>

        <ReactToPrint
          trigger={() => (
            <Button type="button" fullWidth color="secondary" variant="contained" sx={{ mt: 4 }}>
              Imprimir
            </Button>
          )}
          onAfterPrint={() => {
            alterarPassageiro(null);
            limparInput();
          }}
          content={() => componentRef.current}
        />
      </Container>
    </>
  );
};
