import { Box } from "@mui/material";
import styled, { keyframes } from "styled-components"
import { styled as stld } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

const slideIn = keyframes`
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
`;

const slideOut = keyframes`
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
`;

export const BackdropDrawer = styled.div<{ show: boolean }>`
  background: #00000030;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: ${({ show }) => show ? 'block' : 'none'};
`

export const DrawerStyle = styled.div<{ showDrawer: boolean }>`
  height: 100%;
  position: fixed;
  right: 0;
  min-width: 380px;
  width: 25%;
  background-color: #FFFFFF;
  box-shadow: 0 0 4px 4px rgba(0,0,0,0.05);
  padding: 30px;
  overflow-y: auto;
  z-index: 9999;

  h2 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  animation: ${({ showDrawer }) => showDrawer ? slideIn : slideOut} 0.3s ease-in-out forwards;
`

export const TituloDrawer = styled.span`
  color: #7D7D7D;
  font-family: 'Roboto';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
`

export const BoxConteudoDrawer = styled(Box)`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  height: 88vh;
  margin-top: 20px;

  .filtros {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
    font-weight: bold;
  }
`

export const ItemListaConversas = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  gap: 12px;
  padding: 8px;
  border-radius: 4px;
  background: #F2F2F2;

  .div-infos {
    h5 {
      overflow: hidden;
      color: rgba(0, 0, 0, 0.87);
      text-overflow: ellipsis;
      font-family: 'Roboto';
      font-size: 17px;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    p {
      overflow: hidden;
      color: #49454F;
      text-overflow: ellipsis;
      font-family: 'Roboto';
      font-size: 13px;
      font-weight: 400;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`

export const BoxListaMensagens = styled(Box)`
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  height: 50vh;
`

export const BoxMensagem = styled(Box) <{ enviado: boolean }>`
  border-radius: ${({ enviado }) => enviado ? '12.461px 12.461px 4.984px 12.461px' : '4.984px 12.461px 12.461px 12.461px;'};
  display: flex;
  padding: 9.969px 14.953px;
  align-items: flex-start;
  gap: 6.23px;
  flex-shrink: 0;
  background: ${({ enviado }) => enviado ? '#FDB825' : '#E3E3E3'};
  color: #1F1F1F;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.125px;
  max-width: 80%;
`

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

export const Textarea = stld(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    margin-bottom: 10px;

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);