import { Backdrop, CircularProgress, Typography } from '@mui/material';

type Props = {
  open: boolean;
  title: string;
};

export const LoadingBackdrop = ({ open, title }: Props) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
      open={open}>
      <CircularProgress color="inherit" />
      <Typography variant="body2" sx={{ mt: 2 }}>
        {title}
      </Typography>
    </Backdrop>
  );
};

