export const PATHS = {
  Home: '/',
  LeituraPassagem: {
    Principal: '/leitura-passagem',
  },
  LeituraGeralPassagem: {
    Principal: '/leitura-geral-passagem',
  },
  SelecaoPontoEmbarque: {
    Principal: '/selecao-ponto-embarque',
  },
  Promotores: {
    Principal: '/embarque-promotor/:codigo',
  },
} as const;
