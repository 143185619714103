import { Box, BoxProps } from '@mui/system';

export const FlexBoxColumn = ({ children, ...props }: BoxProps) => {
  return (
    <Box flex={1} {...props} display="flex" flexDirection="column">
      {children}
    </Box>
  );
};

