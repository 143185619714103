import { useSnackbar } from 'notistack';
import { LoginRequest } from '../../../models/Login/LoginRequestModel';
import { EmbarqueModel } from '../../../models/PontoEmbarque/PontoEmbarqueModel';
import { usePwaService } from '../../../services/pwa.service';

export const useSelecaoPontoEmbarqueController = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { iniciarEmbarque, embarqueEmAndamento } = usePwaService();

  async function iniciarNovoEmbarque(request: EmbarqueModel) {
    let result = await iniciarEmbarque(request);
    enqueueSnackbar('Embarque iniciado com sucesso!', { variant: 'success' });
    return result;
  }

  async function obterEmbarqueEmAndamento(request: LoginRequest) {
    await embarqueEmAndamento(request);
  }

  return {
    obterEmbarqueEmAndamento,
    iniciarNovoEmbarque,
  };
};
