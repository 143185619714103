import { mdiMenu } from '@mdi/js';
import { Icon } from '@mdi/react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import { EmbarqueModel, PontoEmbarqueModel } from '../../../models/PontoEmbarque/PontoEmbarqueModel';
import { usePwaService } from '../../../services/pwa.service';
import { useUsuarioLocalStorageService } from '../../../services/usuario.localstorage.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { SquadAppActions } from '../../../store/squadApp/passagemActions';
import { selectPontoEmbarque } from '../../../store/squadApp/passagemSelectors';
import { AppContainer } from '../../components/AppContainer';
import { HelmetTitle } from '../../components/HelmetTitle';
import { LoadingBackdrop } from '../../components/LoadingBackdrop';
import { PATHS } from '../../routes/paths';
import { useSelecaoPontoEmbarqueController } from './SelecaoPontoEmbarqueController';

type Props = ReturnType<typeof useSelecaoPontoEmbarqueController>;

export const LeituraPassagemView = ({ obterEmbarqueEmAndamento, iniciarNovoEmbarque }: Props) => {
  const context = useAuth();
  const navigate = useNavigate();

  const { obterPontosDeEmbarque } = usePwaService();
  const { obterUsuario, salvarPontoEmbarque } = useUsuarioLocalStorageService();
  const usuario = obterUsuario();

  const [listaPontoEmbarque, setListaPontoEmbarque] = useState<PontoEmbarqueModel[]>([]);
  const [pontoEmbarqueSelect, setPontoEmbarqueSelect] = useState<string>('');
  const [dadosViagem, setDadosViagem] = useState<any>({});
  const [isloading, setIsloading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const pontoEmbarque = useAppSelector(selectPontoEmbarque);

  const refHiddenLink = useRef<HTMLAnchorElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSair = () => {
    setAnchorEl(null);
    context.Logout();
  };
  const handlePesquisaGeral = () => {
    navigate(PATHS.LeituraGeralPassagem.Principal);
  };

  useEffect(() => {
    listarPonstosEmbarque();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function listarPonstosEmbarque() {
    const result = await obterPontosDeEmbarque(usuario);
    setListaPontoEmbarque(result.data);
  }

  async function iniciarNovoEmbarqueHandler() {
    const request: EmbarqueModel = {
      motoristaCpf: usuario.motoristaCpf.toString(),
      eventoId: pontoEmbarque!.eventoId,
      cidadeOrigemId: pontoEmbarque!.cidadeOrigemId,
      dataHoraKey: pontoEmbarque!.dataHoraKey,
    };
    try {
      setIsloading(true);
      let viagem = await iniciarNovoEmbarque(request);
      setDadosViagem(viagem);
      console.log('DADOS DA VIAGEM')
      console.log(viagem)
      setTimeout(()=>{
        if(!refHiddenLink?.current)
          console.log('NÃO FOI POSSÍVEL CLICAR NO LINK')
        refHiddenLink.current?.click();
      }, 500);
      setTimeout(()=>{
        navigate(PATHS.LeituraPassagem.Principal);  
      }, 1000);
    } finally {
      setIsloading(false);
    }
  }

  return (
    <>
      <HelmetTitle subtitulo="Seleção de Ponto de embarque" />

      <AppBar position="sticky" color="secondary">
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleClick}>
            <Icon path={mdiMenu} size={1} />
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <MenuItem onClick={handleCloseSair}>Sair</MenuItem>
            <MenuItem onClick={handlePesquisaGeral}>Pesquisa geral</MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Seleção de embarque
          </Typography>
        </Toolbar>
      </AppBar>

      <AppContainer>
        <Box sx={{ display: 'flex', mt: 1 }}></Box>
        <Box sx={{ mt: 2 }}>
          <InputLabel>Ponto de embarque:</InputLabel>

          <Box sx={{ display: 'flex', mt: 1 }}>
            <Select
              fullWidth
              style={{ maxWidth: '70%' }}
              id="ponto-de-embarque"
              onChange={(event: SelectChangeEvent<string>) => {
                const pontoEmbarque = JSON.parse(event.target.value) as PontoEmbarqueModel;
                dispatch(SquadAppActions.armazenarPontoEmbarque({ pontoEmbarque: pontoEmbarque }));
                salvarPontoEmbarque(pontoEmbarque);
                setPontoEmbarqueSelect(JSON.stringify(pontoEmbarque));
              }}
              value={pontoEmbarqueSelect}>
              {listaPontoEmbarque.map((item) => (
                <MenuItem key={item.dataHoraKey} value={JSON.stringify(item)}>
                  {item.descricao}
                </MenuItem>
              ))}
            </Select>
            <Button
              color="secondary"
              variant="contained"
              sx={{ ml: 1, pl: 4, pr: 4 }}
              onClick={iniciarNovoEmbarqueHandler}
              disabled={!pontoEmbarque}>
              Iniciar embarque
            </Button>
          </Box>
          <a id='motoraLink' ref={refHiddenLink} href={`rsp://motora.ai/start-travel?driver_cpf=${dadosViagem?.motoristaCpf??""}&car_plate=${dadosViagem?.onibusDescricao??""}`}></a>
        </Box>
        {isloading && <LoadingBackdrop open title="Carregando..." />}
        {/* <ModalNotificacaoGeoLocation aberto={modalNotificacaoAberto} fechar={() => {}} /> */}
      </AppContainer>
    </>
  );
};
