import { configureStore } from '@reduxjs/toolkit';
import { squadAppSlice } from './squadApp/squadAppSlice';

const store = configureStore({
  reducer: {
    squadApp: squadAppSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
