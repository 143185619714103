import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocationModel } from '../../../models/Location/LocationModel';
import { EmbarcarPassageiroModel, EmbarcarPassageirosMultiplosModel, PassageiroModel } from '../../../models/Passageiro/PassageiroModel';
import { EmbarqueModel } from '../../../models/PontoEmbarque/PontoEmbarqueModel';
import { usePwaService } from '../../../services/pwa.service';
import { useUsuarioLocalStorageService } from '../../../services/usuario.localstorage.service';
import { PATHS } from '../../routes/paths';
import { NotistackActions } from './components/NotistackActions';

const LOCALIZADOR_ID = 'localizador';

export const useLeituraPassagemController = () => {
  const [modalLeituraCodigoDeBarrasAberta, setModalLeituraCodigoDeBarrasAberta] = useState(false);
  const [embarqueIsloading, setEmbarqueIsloading] = useState<boolean>(false);
  const [passageirosSelecionados, setPassageirosSelecionados] = useState<PassageiroModel[] | null>(null);
  const [codigoQrCode, setCodigoQrCode] = useState<string>('');
  const { listarPassageiros, embarcarPassageiro, embarcarPassageirosMultiplos, finalizarEmbarque } = usePwaService();
  const { obterUsuario, obterPontoEmbarque } = useUsuarioLocalStorageService();
  const usuario = obterUsuario();
  const pontoEmbarque = obterPontoEmbarque();

  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  async function alternarLeituraCodigoDeBarras(termo: string) {
    setQRCodeValue(termo);
    setModalLeituraCodigoDeBarrasAberta(!modalLeituraCodigoDeBarrasAberta);
  }

  function setQRCodeValue(newValue: string) {
    setCodigoQrCode(newValue);
  }

  function selecionarPassageiros(newValue: PassageiroModel[] | null) {
    setPassageirosSelecionados(newValue);
  }

  async function setModalQrCode() {
    setModalLeituraCodigoDeBarrasAberta(!modalLeituraCodigoDeBarrasAberta);
  }
  
  async function embarcar(location: LocationModel, isMultiPassageiros: boolean, bagagens?: string[]): Promise<void> {
    try {
      setEmbarqueIsloading(true);
      if (isMultiPassageiros) {
        let passageirosParaEmbarcar: Array<{passageiroId: number; passageiroNome: string;}> = []
        
        passageirosSelecionados && passageirosSelecionados.map((passageiro) => {
          let obj = {
            passageiroId: passageiro.passageiroId,
            passageiroNome: passageiro.nome
          }

          passageirosParaEmbarcar.push(obj)
        })

        const request: EmbarcarPassageirosMultiplosModel = {
          eventoId: pontoEmbarque!.eventoId,
          cidadeOrigemId: pontoEmbarque!.cidadeOrigemId,
          dataHoraKey: pontoEmbarque!.dataHoraKey,
          latitude: location.latitude,
          longitude: location.longitude,
          motoristaCpf: usuario.motoristaCpf.toString(),
          passageirosEmbarcados: passageirosParaEmbarcar,
          viagemId: passageirosSelecionados![0].viagemId,
        };
        let error = false;
        let erros: any = {}
        const response: any = await embarcarPassageirosMultiplos(request);

        for(const resultado of response?.resultados??[]){
            if(!resultado.sucesso){
              if(!erros[resultado.mensagem]){
                erros[resultado.mensagem] = []
              }
              erros[resultado.mensagem].push(`${resultado.passageiro} (${resultado.numeroEmbarque})`)
              error = true;
            }
        }
        if(error){
          for(let mensagem in erros){
            let errorStr = `Erro na sympla: ${mensagem}     `;
            errorStr += erros[mensagem].join(";  ")  
            enqueueSnackbar(errorStr, { variant: 'error', persist: true,  action: NotistackActions });
          }
          enqueueSnackbar('Passageiros embarcados, mas checkout na sympla não realizado!', { variant: 'warning' });
        }
        else
          enqueueSnackbar('Passageiros embarcados com sucesso!', { variant: 'success' });

        setPassageirosSelecionados(null);
      } else {
        const request: EmbarcarPassageiroModel = {
          eventoId: pontoEmbarque!.eventoId,
          cidadeOrigemId: pontoEmbarque!.cidadeOrigemId,
          dataHoraKey: pontoEmbarque!.dataHoraKey,
          latitude: location.latitude,
          longitude: location.longitude,
          motoristaCpf: usuario.motoristaCpf.toString(),
          passageiroId: passageirosSelecionados![0].passageiroId,
          passageiroNome: passageirosSelecionados![0].nome,
          viagemId: passageirosSelecionados![0].viagemId,
          bagagens: bagagens,
        };
        let error = false;
        let erros: any = {}
        const response: any = await embarcarPassageiro(request);

        for(const resultado of response?.resultados??[]){
          if(!resultado.sucesso){
            if(!erros[resultado.mensagem]){
              erros[resultado.mensagem] = []
            }
            erros[resultado.mensagem].push(`${resultado.passageiro} (${resultado.numeroEmbarque})`)
            error = true;
          }
        }
        if(error){
          for(let mensagem in erros){
            let errorStr = `Erro na sympla: ${mensagem}     `;
            errorStr += erros[mensagem].join(";  ")  
            enqueueSnackbar(errorStr, { variant: 'error', persist: true,  action: NotistackActions });
          }
          enqueueSnackbar('Passageiro embarcado, mas checkout na sympla não realizado!', { variant: 'warning' });
        }
        else
          enqueueSnackbar('Passageiro embarcado com sucesso!', { variant: 'success' });
        
        setPassageirosSelecionados(null);
      }
    } finally {
      setEmbarqueIsloading(false);
    }
  }

  async function finalizar(): Promise<any> {
    try {
      const request: EmbarqueModel = {
        motoristaCpf: usuario.motoristaCpf.toString(),
        eventoId: pontoEmbarque!.eventoId,
        cidadeOrigemId: pontoEmbarque!.cidadeOrigemId,
        dataHoraKey: pontoEmbarque!.dataHoraKey,
      };
      setEmbarqueIsloading(true);
      let result = await finalizarEmbarque(request);
      enqueueSnackbar('Embarque finalizado!', { variant: 'success' });
      setPassageirosSelecionados(null);
      return result;
    } finally {
      setEmbarqueIsloading(false);
    }
  }

  return {
    state: {
      LOCALIZADOR_ID,
      embarqueIsloading,
      modalLeituraCodigoDeBarrasAberta,
      usuario,
      passageirosSelecionados,
      codigoQrCode,
    },
    alternarLeituraCodigoDeBarras,
    setModalQrCode,
    embarcar,
    finalizar,
    selecionarPassageiros,
    setQRCodeValue,
    pontoEmbarque
  };
};
