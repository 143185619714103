import { mdiBusStop, mdiFlagCheckered, mdiHumanGreeting, mdiMapMarkerOutline, mdiMenu, mdiSeatReclineExtra, mdiSwapVerticalVariant, mdiAccountTie } from '@mdi/js';
import { Icon } from '@mdi/react';
import { LoadingButton } from '@mui/lab';
import { AppBar, Box, IconButton, Menu, MenuItem, Select, SelectChangeEvent, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import { LocationModel, LOCATION_INITIAL_STATE } from '../../../models/Location/LocationModel';
import { PassageiroModel } from '../../../models/Passageiro/PassageiroModel';
import { AppContainer } from '../../components/AppContainer';
import { HelmetTitle } from '../../components/HelmetTitle';
import { LoadingBackdrop } from '../../components/LoadingBackdrop';
import { ModalImpressao } from '../../components/ModalImpressao/ModalImpressao';
import { PATHS } from '../../routes/paths';
import { GridPassageirosPromotor } from './components/GridPassageirosPromotor';
import { ModalLeituraCodigoBarras } from './components/ModalLeituraCodigoBarras';
import { useEmbarquePromotorController } from './EmbarquePromotorController';
import { usePromotorService } from '../../../services/promotor.service';
import { Horario, INFOS_EMBARQUE_INITIAL_STATE, InformacoesEmbarque } from '../../../models/Promotor';
import { ModalConfirmarFinalizarEmbarque } from './components/ModalConfirmarFinalizarEmbarque';

type Props = ReturnType<typeof useEmbarquePromotorController>;

export const EmbarquePromotorView = ({
  state: {
    LOCALIZADOR_ID,
    modalLeituraCodigoDeBarrasAberta,
    embarqueIsloading,
    usuario,
    passageirosSelecionados,
    codigoQrCode,
  },
  alternarLeituraCodigoDeBarras,
  setModalQrCode,
  embarcar,
  finalizarEmbarque,
  selecionarPassageiros,
  setQRCodeValue
}: Props) => {
  const context = useAuth();
  const codigo = window.location.pathname.split('/')[2]
  const { obterPassageirosPromotor, iniciarEmbarquePromotor } = usePromotorService();
  const navigate = useNavigate();

  const [passageiros, setPassageiros] = useState<PassageiroModel[]>([]);
  const [passageirosFiltrados, setPassageirosFiltrados] = useState<PassageiroModel[]>([]);
  const [location, setLocation] = useState<LocationModel | null>(LOCATION_INITIAL_STATE);
  const [infosEmbarque, setInfosEmbarque] = useState<InformacoesEmbarque>(INFOS_EMBARQUE_INITIAL_STATE);
  const [listaHorarios, setListaHorarios] = useState<Horario[]>([]);
  const [listaDestinos, setListaDestinos] = useState<Array<string>>([]);
  const [horarioSelect, setHorarioSelect] = useState<string>("0");
  const [destinoSelect, setDestinoSelect] = useState<string>("Todos os destinos");
  const [showModalConfirmacaoFinalizarEmbarque, setShowModalConfirmacaoFinalizarEmbarque] = useState<boolean>(false);
  const [modalImpressao, setModalImpressao] = useState<boolean>(false);
  const [isEmbarqueIniciado, setIsEmbarqueIniciado] = useState<boolean>(false);
  const [passageirosIsloading, setPassageirosIsloading] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSair = () => {
    setAnchorEl(null);
    context.Logout();
  };

  async function obterPassageiros() {
    try {
      setPassageirosIsloading(true);
      const response = await obterPassageirosPromotor(codigo);
      let quantPassageirosEmbarcados = response.filter(passageiro => passageiro.embarcado == true).length
      setPassageiros(response);
      setPassageirosFiltrados(response);
      setInfosEmbarque(
        {
          ...infosEmbarque,
          quantidadePassageiros: response.length,
          quantidadePassageirosFiltro: response.length,
          quantidadePassageirosEmbarcados: quantPassageirosEmbarcados,
          porcentagemPassageirosEmbarcados: quantPassageirosEmbarcados && response.length ? (quantPassageirosEmbarcados * 100) / response.length : 0
        }
      );

      const arrayDestinos = response.map(objeto => infosEmbarque.sentidoViagem.toUpperCase() == 'IDA' ? objeto.cidadeDestinoNome : objeto.cidadeOrigemNome)
      .filter((valor, index, self) => self.indexOf(valor) === index);
      arrayDestinos.unshift("Todos os destinos")

      setListaDestinos(arrayDestinos)
    } finally {
      setPassageirosIsloading(false);
    }
  }

  async function embarcarHandler(bagagens?: string[]): Promise<void> {
    let isMultiPassageiros = passageirosSelecionados && passageirosSelecionados.length > 1 ? true : false;
    let ids = await embarcar(location!, isMultiPassageiros, bagagens, infosEmbarque.sentidoViagem);
    let passageirosCopia: PassageiroModel[] = passageiros.map((x: any) => {
      if (ids.indexOf(x.passageiroId) != -1)
        return { ...x, embarcado: true }
      return x;
    });

    setPassageiros(passageirosCopia);

    if (horarioSelect == "0") {
      let quantPassageirosEmbarcados = passageirosCopia.filter(passageiro => passageiro.embarcado == true).length
      setPassageirosFiltrados(passageirosCopia);
      setInfosEmbarque(
        {
          ...infosEmbarque,
          quantidadePassageiros: passageirosCopia.length,
          quantidadePassageirosFiltro: passageirosCopia.length,
          quantidadePassageirosEmbarcados: quantPassageirosEmbarcados,
          porcentagemPassageirosEmbarcados: quantPassageirosEmbarcados && passageirosCopia.length ? (quantPassageirosEmbarcados * 100) / passageirosCopia.length : 0
        }
      );
    } else {
      let passageirosFilter: PassageiroModel[] = passageirosCopia.filter(passageiro => passageiro.dataHoraKey === horarioSelect || passageiro.dataHoraKeyR === horarioSelect)
      let quantPassageirosEmbarcados = passageirosFilter.filter(passageiro => passageiro.embarcado == true).length
      setPassageirosFiltrados(passageirosFilter)
      setInfosEmbarque(
        {
          ...infosEmbarque,
          quantidadePassageiros: passageirosCopia.length,
          quantidadePassageirosFiltro: passageirosFilter.length,
          quantidadePassageirosEmbarcados: quantPassageirosEmbarcados,
          porcentagemPassageirosEmbarcados: quantPassageirosEmbarcados && passageirosFilter.length ? (quantPassageirosEmbarcados * 100) / passageirosFilter.length : 0
        }
      );
    }

  }

  async function iniciarNovoEmbarqueHandler() {
    try {
      let response = await iniciarEmbarquePromotor(codigo);
      let horariosArr: Horario[] = []

      horariosArr.push({
        horarioOriginal: "0",
        horarioFormatado: "Todos os horários"
      })

      response.horarios.map(horario => {
        let hora = horario.split("_")[1].split(":");
        horariosArr.push({
          horarioOriginal: horario,
          horarioFormatado: hora[0] + ":" + hora[1]
        })
      })

      setListaHorarios(horariosArr);
      setInfosEmbarque(
        {
          ...infosEmbarque,
          nomePontoEmbarque: response.nomePontoEmbarque,
          nomePromotor: response.nomePromotor,
          nomeDestino: response.nomeDestino,
          sentidoViagem: response.sentidoViagem
        }
      );
      setIsEmbarqueIniciado(true)
    } catch (err) {

    }
  }

  function handleFiltrarPorHorario(horario: string) {
    if (horario === "0") {
      let passageirosFilter: PassageiroModel[] = passageiros
      if(destinoSelect !== "" && destinoSelect !== "Todos os destinos")
        passageirosFilter = filtrarDestino(passageirosFilter, destinoSelect)

      let quantPassageirosEmbarcados = passageirosFilter.filter(passageiro => passageiro.embarcado == true).length
      setInfosEmbarque(
        {
          ...infosEmbarque,
          quantidadePassageirosFiltro: passageirosFilter.length,
          quantidadePassageirosEmbarcados: quantPassageirosEmbarcados,
          porcentagemPassageirosEmbarcados: quantPassageirosEmbarcados && passageirosFilter.length ? (quantPassageirosEmbarcados * 100) / passageirosFilter.length : 0
        }
      );
      
      setPassageirosFiltrados(passageirosFilter)
      setHorarioSelect("0")
    } else {
      let passageirosFilter: PassageiroModel[] = filtrarHorario(passageiros, horario)
      if(destinoSelect !== "" && destinoSelect !== "Todos os destinos")
        passageirosFilter = filtrarDestino(passageirosFilter, destinoSelect)

      let quantPassageirosEmbarcados = passageirosFilter.filter(passageiro => passageiro.embarcado == true).length
      setInfosEmbarque(
        {
          ...infosEmbarque,
          quantidadePassageirosFiltro: passageirosFilter.length,
          quantidadePassageirosEmbarcados: quantPassageirosEmbarcados,
          porcentagemPassageirosEmbarcados: quantPassageirosEmbarcados && passageirosFilter.length ? (quantPassageirosEmbarcados * 100) / passageirosFilter.length : 0
        }
      );
      setPassageirosFiltrados(passageirosFilter)
      setHorarioSelect(horario);
    }
  }

  function handleFiltrarPorDestino(destino: string) {
    if (destino === "" || destino == 'Todos os destinos') {
      let passageirosFilter: PassageiroModel[] = passageiros
      if(horarioSelect !== "" && horarioSelect !== "0")
        passageirosFilter = filtrarHorario(passageirosFilter, horarioSelect)

      let quantPassageirosEmbarcados = passageirosFilter.filter(passageiro => passageiro.embarcado == true).length
      setInfosEmbarque(
        {
          ...infosEmbarque,
          quantidadePassageirosFiltro: passageirosFilter.length,
          quantidadePassageirosEmbarcados: quantPassageirosEmbarcados,
          porcentagemPassageirosEmbarcados: quantPassageirosEmbarcados && passageirosFilter.length ? (quantPassageirosEmbarcados * 100) / passageirosFilter.length : 0
        }
      );
      setPassageirosFiltrados(passageirosFilter)
      setDestinoSelect("Todos os destinos")
    } else {
      let passageirosFilter: PassageiroModel[] = filtrarDestino(passageiros, destino)
      if(horarioSelect !== "" && horarioSelect !== "0")
        passageirosFilter = filtrarHorario(passageirosFilter, horarioSelect)

      let quantPassageirosEmbarcados = passageirosFilter.filter(passageiro => passageiro.embarcado == true).length
      setInfosEmbarque(
        {
          ...infosEmbarque,
          quantidadePassageirosFiltro: passageirosFilter.length,
          quantidadePassageirosEmbarcados: quantPassageirosEmbarcados,
          porcentagemPassageirosEmbarcados: quantPassageirosEmbarcados && passageirosFilter.length ? (quantPassageirosEmbarcados * 100) / passageirosFilter.length : 0
        }
      );
      setPassageirosFiltrados(passageirosFilter)
      setDestinoSelect(destino);
    }
  }

  function filtrarHorario(passageirosAFiltrar: PassageiroModel[], horario: string){
    return passageirosAFiltrar
    .filter(passageiro => 
      passageiro.dataHoraKey === horario || 
      passageiro.dataHoraKeyR === horario
    )
  }

  function filtrarDestino(passageirosAFiltrar: PassageiroModel[], destino: string){
    return passageirosAFiltrar
    .filter(
      passageiro => 
        (infosEmbarque.sentidoViagem.toUpperCase() === 'IDA' ? passageiro.cidadeDestinoNome : passageiro.cidadeOrigemNome) === destino
    )
  }

  useEffect(() => {
    (async () => {
      await iniciarNovoEmbarqueHandler()
    })()
  }, []);

  useEffect(() => {
    (async () => {
      if (isEmbarqueIniciado) {
        navigator.geolocation.getCurrentPosition(function (position) {
          setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
        });
        await obterPassageiros();
      }
    })()
  }, [isEmbarqueIniciado]);

  return (
    <>
      {/* {!isEmbarqueIniciado && <LoadingBackdrop open title="Carregando..." />} */}

      <HelmetTitle subtitulo="Busca de passageiro" />

      <AppBar position="sticky" color="secondary">
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleClick}>
            <Icon path={mdiMenu} size={1} />
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <MenuItem onClick={handleCloseSair}>Sair</MenuItem>
            <MenuItem onClick={() => navigate(PATHS.LeituraGeralPassagem.Principal)}>Pesquisa geral</MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Embarque SQUAD
          </Typography>
        </Toolbar>
      </AppBar>

      <AppContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <p className="mb-1">
              <Icon path={mdiMapMarkerOutline} size={0.8} /> 
              {infosEmbarque.nomePontoEmbarque} 
              <br/>
              <Icon path={mdiFlagCheckered} size={0.8} />
              {infosEmbarque.nomeDestino.join(' | ')}</p>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <p className="mb-1"><Icon path={mdiBusStop} size={0.8} /> {infosEmbarque.quantidadePassageiros}</p>
            <p className="mb-1"><Icon path={mdiSwapVerticalVariant} size={0.8} /> {infosEmbarque.sentidoViagem}</p>
            <p className="mb-1"><Icon path={mdiAccountTie} size={0.8} /> {infosEmbarque.nomePromotor}</p>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <p className="mb-1"><Icon path={mdiHumanGreeting} size={0.8} /> {infosEmbarque.quantidadePassageirosFiltro}</p>
            <p className="mb-1"><Icon path={mdiSeatReclineExtra} size={0.8} /> {infosEmbarque.quantidadePassageirosEmbarcados}</p>
            <p className="mb-1"><Icon path={mdiFlagCheckered} size={0.8} /> {infosEmbarque.porcentagemPassageirosEmbarcados?.toFixed(2)}%</p>
          </Box>
          <Box display={'flex'} gap={5} sx={{ width: '60%' }}>
            <Box sx={{ width: '50%' }}>
              <Select
                fullWidth
                id="horario"
                onChange={(event: SelectChangeEvent<string>) => {
                  handleFiltrarPorHorario(event.target.value)
                }}
                defaultValue='0'
                value={horarioSelect}>
                {listaHorarios.map((item, index) => (
                  <MenuItem key={`item_${index}`} value={item.horarioOriginal}>
                    {item.horarioFormatado}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Select
                fullWidth
                id="destino"
                onChange={(event: SelectChangeEvent<string>) => {
                  handleFiltrarPorDestino(event.target.value)
                }}
                defaultValue='0'
                value={destinoSelect}>
                {listaDestinos.map((item, index) => (
                  <MenuItem key={`item_${index}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
        <div>
          <GridPassageirosPromotor
            defaultList={passageirosFiltrados}
            selecionarPassageiros={selecionarPassageiros}
            isMultiPassageiros={passageirosSelecionados && passageirosSelecionados.length > 1 ? true : false}
            embarcar={embarcarHandler}
            ehMotorista={false}
            setModalQrCode={setModalQrCode}
            abrirModalImpressao={() => setModalImpressao(true)}
            codigoQrCode={codigoQrCode}
            setQRCodeValue={setQRCodeValue}
            sentidoViagem={infosEmbarque.sentidoViagem}
            linkPromotoria={codigo}
          />
        </div>
        <Box sx={{ display: 'flex', mt: 1 }}>
          <LoadingButton
            color="error"
            loading={false}
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 1, mb: 2 }}
            onClick={() => setShowModalConfirmacaoFinalizarEmbarque(true)}>
            Finalizar embarque
          </LoadingButton>
        </Box>
        {embarqueIsloading && <LoadingBackdrop open title="Carregando..." />}
        {passageirosIsloading && <LoadingBackdrop open title="Carregando..." />}
      </AppContainer>

      <ModalConfirmarFinalizarEmbarque
        aberto={showModalConfirmacaoFinalizarEmbarque}
        fechar={() => setShowModalConfirmacaoFinalizarEmbarque(false)}
        finalizarEmbarque={() => finalizarEmbarque(codigo)}
      />

      <ModalLeituraCodigoBarras
        aberto={modalLeituraCodigoDeBarrasAberta}
        fechar={setModalQrCode}
        confirmarLeitura={alternarLeituraCodigoDeBarras}
      />
      <ModalImpressao
        passageirosSelecionados={passageirosSelecionados?.length ? passageirosSelecionados : passageiros}
        aberto={modalImpressao}
        fechar={() => {
          setModalImpressao(false);
        }}
      />
    </>
  );
};
