import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Dialog, DialogContent } from '@mui/material';
import { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { PassageiroModel } from '../../../models/Passageiro/PassageiroModel';
import { FlexBoxColumn } from '../FlexBoxColumn';
import * as S from './styles/index';

type Props = {
  aberto: boolean;
  passageirosSelecionados: PassageiroModel[] | null;
  fechar: () => void;
};

export const ModalImpressao = ({ aberto, fechar, passageirosSelecionados }: Props) => {
  const componentRef = useRef<HTMLDivElement>(null);
  return (
    <Dialog open={aberto} onClose={fechar}>
      <S.IconButtonCustom size="small" color="inherit" aria-label="menu" onClick={fechar}>
        <Icon path={mdiClose} size={1}  />
      </S.IconButtonCustom>
      <DialogContent>
        <FlexBoxColumn>
          <ReactToPrint
            trigger={() => (
              <Button
                type="button"
                fullWidth
                color="secondary"
                variant="contained"
                sx={{ mt: 4 }}
                style={{ position: 'sticky', top: '10px' }}>
                Imprimir
              </Button>
            )}
            content={() => componentRef.current}
          />
          <div ref={componentRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {passageirosSelecionados?.map((data) => {
              return (
                <S.DivPrintContent key={data.passageiroId}>
                  <span>Olá {data.nome}</span>
                  <span style={{ textAlign: 'center' }}>Tudo está pronto para sua viagem para {data.destino}.</span>
                  <img src={data.qrCode} alt="QrCode" style={{ height: '200px', width: '200px' }} />
                  <span>N° reserva: #{data.numeroBilhete}</span>
                  {data.onibusNumero && (
                    <div style={{ textAlign: 'center' }}>
                      <span>&nbsp;</span>
                      <span style={{ textAlign: 'center' }}>O número do seu ônibus é {data.onibusNumero}</span>
                    </div>
                  )}
                  <span>&nbsp;</span>
                  <span style={{ color: 'lightgray' }}>&nbsp;.</span>
                </S.DivPrintContent>
              );
            })}
          </div>
        </FlexBoxColumn>
      </DialogContent>
    </Dialog>
  );
};
