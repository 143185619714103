import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

export const NotistackActions = (snackbarId: any) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <>
      <Button
        type="button"
        fullWidth
        color="secondary"
        variant="contained"
        onClick={() => closeSnackbar(snackbarId)}
      >
        Fechar
      </Button>
    </>
  );
}