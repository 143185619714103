import { LoginRequest } from '../models/Login/LoginRequestModel';
import { PontoEmbarqueModel } from '../models/PontoEmbarque/PontoEmbarqueModel';

export const useUsuarioLocalStorageService = () => {
  const salvarDadosCache = (data: LoginRequest | null) => {
    data ? localStorage.setItem('usuario', JSON.stringify(data)) : localStorage.setItem('usuario', '');
  };

  const obterUsuario = (): LoginRequest => {
    const usuario = localStorage.getItem('usuario');
    const usuarioParsed: LoginRequest = usuario ? JSON.parse(usuario) : null;

    return usuarioParsed;
  };

  const salvarPontoEmbarque = (data: PontoEmbarqueModel | null) => {
    data ? localStorage.setItem('pontoEmbarque', JSON.stringify(data)) : localStorage.setItem('pontoEmbarque', '');
  };

  const obterPontoEmbarque = (): PontoEmbarqueModel => {
    const pontoEmbarque = localStorage.getItem('pontoEmbarque');
    const pontoEmbarqueParsed: PontoEmbarqueModel = pontoEmbarque ? JSON.parse(pontoEmbarque) : null;

    return pontoEmbarqueParsed;
  };

  return {
    salvarDadosCache,
    obterUsuario,
    salvarPontoEmbarque,
    obterPontoEmbarque,
  };
};
