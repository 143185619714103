import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type Props = {
  aberto: boolean;
  fechar: () => void;
  finalizarEmbarque: () => void;
};

export const ModalConfirmarFinalizarEmbarque = ({ aberto, finalizarEmbarque, fechar }: Props) => {

  return (
    <Dialog fullWidth maxWidth="md" open={aberto} onClose={() => fechar()}>
      <DialogTitle>Finalizar Embarque</DialogTitle>
      <DialogContent>
        <p>A finalização deve ocorrer após todos os passageiros embarcar. Deseja continuar?</p>
        <p>A finalização do embarque só deverá acontecer uma vez que TODOS os passageiros dessa viagem tiverem embarcados.</p>
        <p>Não é necessário finalizar o embarque quando um passageiro embarca, apenas quando a viagem termina.</p>
      </DialogContent>

      <DialogActions>
        <Button fullWidth sx={{ mx: 2, mb: 1 }} onClick={fechar}>
          Cancelar
        </Button>
        <Button
          color="secondary"
          variant="contained"
          fullWidth sx={{ mx: 2, mb: 1 }}
          onClick={() => {
            finalizarEmbarque()
            fechar()
          }}
        >
          Sim, continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
