import styled from 'styled-components';

export const InfoPontoEmbarque = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
`;
export const DataGridContent = styled.div`
  width: 100%;
  margin-top: 1em;
`;

export const DivPassageiroContent = styled.div`
  display: flex;
  align-items: center;
`;
