import { LoadingButton } from '@mui/lab';
import { Box, createTheme, CssBaseline, InputLabel, TextField, ThemeProvider, Typography } from '@mui/material';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useAuth } from '../../../contexts/auth';
import { LoginRequest } from '../../../models/Login/LoginRequestModel';
import { COLORS } from '../../../utils/colors';
import { HelmetTitle } from '../../components/HelmetTitle';
import * as S from './styles';

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.white,
      contrastText: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
  },
  typography: {
    allVariants: {
      color: COLORS.white,
    },
  },
});

export const Login = () => {
  const context = useAuth();
  const [carregando, setCarregando] = useState(false);
  const [cpfInvalido, setCpfInvalido] = useState(false);
  const [numeroOnibus, setNumeroOnibus] = useState('');

  const login = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      setCarregando(true);
      const data = new FormData(event.currentTarget);
      const cpfConvertido = data
        .get('cpf')!
        .toString()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .replace(/\s/g, '');
      const veiculo = data.get('onibus') ? data.get('onibus')!.toString() : '';

      const request: LoginRequest = {
        motoristaCpf: cpfConvertido,
        veiculo: data.get('onibus') ? data.get('onibus')!.toString() : '',
        ehMotorista: veiculo ? true : false,
      };
      if (cpfConvertido.length !== 11) {
        setCpfInvalido(true);
        return;
      }
      await context.Login(request);
    } finally {
      setCarregando(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <HelmetTitle subtitulo="Login" />

      <CssBaseline />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100%',
          background: COLORS.primary,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 4,
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          maxWidth="400px">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Typography component="h1" variant="h5">
              Bem-vindo!
            </Typography>
            <Typography component="span">Para acessar basta informar seu cpf e número do ônibus.</Typography>
          </Box>

          <Box component="form" onSubmit={login} noValidate sx={{ mt: 1 }}>
            <InputLabel htmlFor="cpf">Cpf</InputLabel>
            <NumberFormat
              style={{ width: '100%' }}
              customInput={TextField}
              required
              fullWidth
              id="cpf"
              name="cpf"
              autoFocus
              sx={{ input: { color: COLORS.white } }}
              onValueChange={(values, source) => {
                if (source.event) {
                  const { formattedValue } = values;
                  if (
                    formattedValue.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '').length !== 11
                  ) {
                    setCpfInvalido(true);
                  } else {
                    setCpfInvalido(false);
                  }
                }
              }}
            />
            {cpfInvalido && <S.CpfMessage>CPF inválido.</S.CpfMessage>}
            <InputLabel htmlFor="password">Ônibus</InputLabel>
            <TextField
              required
              fullWidth
              name="onibus"
              type="onibus"
              id="onibus"
              sx={{ input: { color: COLORS.white } }}
              onChange={(e) => {
                setNumeroOnibus(e.target.value);
              }}
            />
            <LoadingButton
              loading={carregando}
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              disabled={cpfInvalido || !numeroOnibus}>
              Entrar
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
