import { mdiBus, mdiBusStop, mdiChevronLeft, mdiChevronRight, mdiQrcodeScan } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, IconButton, InputLabel, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { PassageiroModel } from '../../../../models/Passageiro/PassageiroModel';
import { COLORS } from '../../../../utils/colors';
import * as S from '../styles/index';
import { ModalInformarBagagens } from './ModalInformarBagagens';
import { formatDateOnlyHour } from '../../../../utils/functions';
import { useSnackbar } from 'notistack';
import { usePwaService } from '../../../../services/pwa.service';
import { ContatoChatDto } from '../../../../models/Chat';
import { OptionSelect } from '../../../../models/Global';
import iconPromotor from "../../../../assets/icons/icon-comunicacao-promotor.svg"
import iconPassageiro from "../../../../assets/icons/icon-comunicacao-passageiro.svg"
import iconMotorista from "../../../../assets/icons/icon-comunicacao-motorista.svg"
import { Drawer } from '../../../components/Drawer';
import { BoxConteudoDrawer, ItemListaConversas } from '../../../components/Drawer/styled';
import ConversaMotorista from './ConversaMotorista';
import Select from "react-select";
import { addHours } from "date-fns";

type Props = {
  defaultList: PassageiroModel[];
  ehMotorista: boolean | undefined;
  isMultiPassageiros: boolean;
  usarKeyR: boolean;
  selecionarPassageiros: (newValue: PassageiroModel[] | null) => void;
  embarcar: (bagagens?: string[]) => Promise<void>;
  setModalQrCode: () => Promise<void>;
  abrirModalImpressao: () => void;
  setQRCodeValue: (value: string) => void;
  codigoQrCode: string;
  motoristaCpf: string;
};

export const GridPassageiros = ({
  defaultList,
  ehMotorista,
  codigoQrCode,
  selecionarPassageiros,
  embarcar,
  setModalQrCode,
  abrirModalImpressao,
  setQRCodeValue,
  isMultiPassageiros,
  usarKeyR,
  motoristaCpf
}: Props) => {
  const { obterContatosChatMotorista } = usePwaService();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [filteredList, setFilteredList] = useState<PassageiroModel[]>([]);
  const [qrCodeInputValue, setQrCodeInputValue] = useState<string>('');
  const [showBagagensModal, setShowBagagensModal] = useState<boolean>(false);
  const [showDrawerMensagens, setShowDrawerMensagens] = useState<boolean>(false);
  const [passageiros, setPassageiros] = useState<OptionSelect[]>([]);
  const [listaPassageiros, setListaPassageiros] = useState<ContatoChatDto[]>([]);
  const [passageiroSelecionado, setPassageiroSelecionado] = useState<OptionSelect>({} as OptionSelect);
  const [conversaSelecionada, setConversaSelecionada] = useState<ContatoChatDto>({} as ContatoChatDto)
  const [listaConversas, setListaConversas] = useState<ContatoChatDto[]>([]);
  
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFilteredList(defaultList);
    setSelectionModel([]);
  }, [defaultList]);

  useEffect(() => {
    if (codigoQrCode) {
      filtrarComQrCode(codigoQrCode);
      setQRCodeValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigoQrCode]);

  useEffect(() => {
    obterContatosChatMotorista(motoristaCpf).then(response => {
      let arr: ContatoChatDto[] = []
      let optPassageiros: OptionSelect[] = []

      if (response.operadores.length > 0) {
        response.operadores.map(operador => {
          arr.push(operador)
        })
      }

      if (response.motoristas.length > 0) {
        response.motoristas.map(motorista => {
          arr.push(motorista)
        })
      }

      if (response.promotores.length > 0) {
        response.promotores.map(promotor => {
          arr.push(promotor)
        })
      }

      if (response.passageiros.length > 0) {
        let passageiros: ContatoChatDto[] = []
        
        arr.push({
          id: "",
          perfil: "Passageiro",
          nome: ""
        })

        response.passageiros.map(passageiro => {
          passageiros.push(passageiro)
          optPassageiros.push({
            label: passageiro.nome,
            value: passageiro.id,
          })
        })

        setListaPassageiros(passageiros)
        setPassageiros(optPassageiros)
      }
      
      setListaConversas(arr)
    })
  }, [])

  function getIcon(tipoUsuario: string) {
    switch(tipoUsuario) {
      case 'Promotor':
        return iconPromotor
      case 'Motorista':
        return iconMotorista
      case 'Passageiro':
        return iconPassageiro
      default:
        return iconPromotor
    }
  }
  
  const formatarHora = (horaStr: any) => {
    let date = addHours(new Date(horaStr), 3);

    const formatar = (num: number): any => `${num>9?'':'0'}${num}`;

    return `${formatar(date.getDate())}/${formatar(date.getMonth()+1)}/${formatar(date.getFullYear())} ${formatar(date.getHours())}:${formatar(date.getMinutes())}`;
  }

  const columns: GridColDef[] = [
    {
      field: 'bilhete',
      headerName: 'Bilhete',
      editable: false,
      flex: 1,
      minWidth: 50,
      renderCell: ({ row }) => {
        return (
          <S.DivPassageiroContent>
            {row.embarcado ? (
              <Icon path={mdiBus} size={1} color={COLORS.green} />
            ) : (
              <Icon path={mdiBusStop} size={1} color={COLORS.red} />
            )}
            <span
              style={{
                whiteSpace: 'break-spaces',
                marginLeft: '0.5em',
              }}>{row.numeroBilhete}</span>
          </S.DivPassageiroContent>
        );
      },
    },
    {
      field: 'passageiro',
      headerName: 'Passageiro',
      editable: false,
      flex: 1,
      minWidth: 50,
      renderCell: ({ row }) => {
        return (
          <S.DivPassageiroContent>
            <span
              style={{
                whiteSpace: 'break-spaces',
                marginLeft: '0.5em',
              }}>{row.nome}</span>
          </S.DivPassageiroContent>
        );
      },
    },
    {
      field: 'destino',
      headerName: 'Destino',
      editable: false,
      flex: 1,
      minWidth: 50,
      renderCell: ({ row }) => {
        return (
          <p>{row.destino}</p>
        );
      },
    },
    {
      field: 'documento',
      headerName: 'Documento',
      editable: false,
      flex: 1,
      minWidth: 50,
      renderCell: ({ row }) => {
        return (
          <p>{row.documento.split('-')[0]}</p>
        );
      },
    },
    {
      field: 'rota',
      headerName: 'Rota',
      editable: false,
      flex: 1,
      minWidth: 50,
      renderCell: ({ row }) => {
        return (
          <p>{row.rota}</p>
        );
      },
    },
    {
      field: usarKeyR ? 'dataHoraKeyR' : 'dataHoraKey',
      headerName: 'Horário de Ida',
      editable: false,
      flex: 1,
      minWidth: 50,
      renderCell: ({ row }) => {
        return (
          <p>{usarKeyR ? formatarHora(row.horaRetorno) : formatarHora(row.horaPartida)}</p>
        );
      },
    },
  ];

  function handleFilterChange(value: string) {
    if (value.length > 0) {
      const filtered = defaultList.filter(
        (x) =>
          x.nome.toLowerCase().includes(value.toLocaleLowerCase()) ||
          x.numeroBilhete.toString().toLowerCase().includes(value.toLocaleLowerCase()) ||
          (x.numeroBilheteSympla??"").toUpperCase().replaceAll("-", "") == value.toUpperCase().replaceAll("-", ""),
      );
      setFilteredList(filtered);
    } else {
      setFilteredList(defaultList);
    }
  }

  function filtrarComQrCode(value: string) {
    setQrCodeInputValue(value);
    const filtered = defaultList.filter((x) => x.numeroBilhete.toString() === value || (x.numeroBilheteSympla??"").replaceAll("-","") == value);
    setFilteredList(filtered);
  }

  function handleEmbarcar() {
    if (isMultiPassageiros) {
      embarcar()
    } else {
      setShowBagagensModal(true)
    }
  }

  function handlePesquisaClick(){
    if(selectionModel.length > 0)
      enqueueSnackbar('Embarque os passageiros selecionados antes de buscar outros.', { variant: 'error' });
  }

  return (
    <>
      <Box sx={{ mt: 1 }} onClick={handlePesquisaClick}>
        <InputLabel>Pesquisar passageiro:</InputLabel>
        <Box sx={{ display: 'flex', mt: 1, gap: '5px' }}>
          <TextField
            required
            fullWidth
            name="passageiro"
            type="text"
            id="passageiro"
            value={qrCodeInputValue}
            onChange={(e) => {
              handleFilterChange(e.target.value);
              setQrCodeInputValue(e.target.value);
            }}
            disabled={selectionModel.length > 0}
          />
          <Button onClick={setModalQrCode} className='scan-qrcode-button' disabled={selectionModel.length > 0}>
            <Icon path={mdiQrcodeScan} size={1} color={COLORS.primary} style={{ marginRight: '8px' }} /> QR Code
          </Button>
        </Box>
      </Box>

      <S.DataGridContent>
        <div style={{ height: 270 }}>
          <DataGrid
            rows={filteredList}
            columns={columns}
            getRowId={(row: PassageiroModel) => row.passageiroId}
            checkboxSelection
            experimentalFeatures={{ newEditingApi: false }}
            hideFooter
            disableColumnMenu
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
              const selectedIDs = new Set(newSelectionModel);
              const selectedRowData = defaultList.filter((row) => selectedIDs.has(row.passageiroId));
              selecionarPassageiros(selectedRowData);
            }}
            selectionModel={selectionModel}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Passageiro não encontrado
                </Stack>
              ),
            }}
          />
        </div>
      </S.DataGridContent>

      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <Button
            type="button"
            fullWidth
            color="primary"
            variant="contained"
            sx={{ mt: 1 }}
            onClick={() => setShowDrawerMensagens(true)}
          >
            Ver mensagens
          </Button>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Button
            type="button"
            fullWidth
            color="secondary"
            variant="contained"
            sx={{ mt: 1 }}
            onClick={() => handleEmbarcar()}
          >
            Embarcar
          </Button>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Button
            type="button"
            fullWidth
            color="info"
            variant="contained"
            sx={{ mt: 1 }}
            onClick={abrirModalImpressao}
            disabled={defaultList.length === 0}>
            Imprimir
          </Button>
        </Box>
      </Box>

      <Drawer showDrawer={showDrawerMensagens} onClose={() => setShowDrawerMensagens(false)}>
        <BoxConteudoDrawer>
          <Box>
            {
              !conversaSelecionada.id && listaConversas.length > 0 && listaConversas.map((itemLista) => (
                <ItemListaConversas mb={1}>
                  <Box display={'flex'} gap={2} alignItems={'center'} width={'100%'}>
                    <img src={getIcon(itemLista.perfil)} alt="" />
                    {
                      itemLista.perfil !== 'Passageiro' &&
                      <div className="div-infos">
                        <h5>{itemLista.nome}</h5>
                        <p className="mt-1">{itemLista.perfil}</p>
                      </div>
                    }

                    {
                      itemLista.perfil === 'Passageiro' &&
                      <Select
                        required
                        placeholder="Selecionar passageiro..."
                        options={passageiros}
                        value={passageiroSelecionado}
                        onChange={(e: any) => setPassageiroSelecionado(e)}
                        styles={{
                          container: (baseStyles) => 
                            // @ts-ignore
                          ({
                            ...baseStyles,
                            width: '100%',
                          }),
                          control: (baseStyles, state) => 
                          // @ts-ignore
                          ({
                            ...baseStyles,
                            borderWidth: state.isFocused ? 0 : 0,
                            borderBottomWidth: state.isFocused ? 1 : 1,
                            fontSize: 16,
                            focus: { borderWidth: 0 },
                            borderRadius: 0,
                            background: 'transparent'
                          }),
                          indicatorSeparator: () => ({
                            display: "none",
                          }),
                        }}
                      />
                    }
                  </Box>
                  <IconButton 
                    aria-label="voltar" 
                    size="medium" 
                    onClick={() => {
                      if (itemLista.perfil === 'Passageiro') {
                        setConversaSelecionada(listaPassageiros.filter(x => x.id === passageiroSelecionado.value)[0])
                      } else {
                        setConversaSelecionada(itemLista)
                      }
                    }}
                  >
                    <Icon path={mdiChevronRight} size={1} />
                  </IconButton>
                </ItemListaConversas>
              ))
            }
          </Box>

          {
            conversaSelecionada.id &&
            <>
              <ItemListaConversas mb={1}>
                <Box display={'flex'} gap={2} alignItems={'center'}>
                  <IconButton aria-label="voltar" size="medium" onClick={() => setConversaSelecionada({} as ContatoChatDto)}>
                    <Icon path={mdiChevronLeft} size={1} />
                  </IconButton>
                  <img src={getIcon(conversaSelecionada.perfil)} alt="" />
                  <div className="div-infos">
                    <h5>{conversaSelecionada.nome}</h5>
                    <p className="mt-1">{conversaSelecionada.perfil}</p>
                  </div>
                </Box>
              </ItemListaConversas>

              <ConversaMotorista motoristaCpf={motoristaCpf} idConversa={conversaSelecionada.id} />
            </>
          }
        
        </BoxConteudoDrawer>
      </Drawer>

      <ModalInformarBagagens
        aberto={showBagagensModal}
        fechar={() => setShowBagagensModal(false)}
        embarcar={embarcar}
      />
    </>
  );
};
