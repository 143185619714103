import { Route, Routes } from 'react-router-dom';
import { Home } from '../pages/Home/Home';
import { LeituraGeralPassagem } from '../pages/leitura-geral-passagem';
import { LeituraPassagem } from '../pages/leitura-passagem';
import { SelecaoPontoEmbarque } from '../pages/selecao-embarque';
import { EmbarquePromotor } from '../pages/embarque-promotor';
import { PATHS } from './paths';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.Home} element={<Home />} />
      <Route path={PATHS.LeituraPassagem.Principal} element={<LeituraPassagem />} />
      <Route path={PATHS.LeituraGeralPassagem.Principal} element={<LeituraGeralPassagem />} />
      <Route path={PATHS.SelecaoPontoEmbarque.Principal} element={<SelecaoPontoEmbarque />} />
      <Route path={PATHS.Promotores.Principal} element={<EmbarquePromotor />} />
    </Routes>
  );
};