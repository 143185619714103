export const COLORS = {
  primary: '#3D3D3D',
  secondary: '#FFB500',

  red: "#c91616",

  white: '#ffffff',
  black: '#000000',
  lightGray40: '#EAEEF440',
  lightGray60: '#EAEEF460',
  gray: '#757985',
  green: '#50C878'
} as const;

