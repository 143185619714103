import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useSnackbar } from 'notistack';
import { useAuth } from '../contexts/auth';
import { useUsuarioLocalStorageService } from '../services/usuario.localstorage.service';
import { BASE_CONFIG } from '../utils/baseConfig';
import { IApiError } from '../utils/types/ApiError';
import { ApiResponse } from '../utils/types/ApiResponse';
import { useErrorSnackbar } from './errorSnackbar';

export type UseApiOptions = {
  semToken?: boolean;
  external?: boolean;
};

export function useApi({ semToken, external }: Partial<UseApiOptions> = {}) {
  const { enqueueSnackbar } = useSnackbar();
  const { tratarErro } = useErrorSnackbar();
  const context = useAuth();

  const api = axios.create({
    baseURL: external ? '' : BASE_CONFIG.BASE_API_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  });

  api.interceptors.request.use(
    async (config) => {    

      return config;
    },
    (error) => {
      if (error.response.status === 403) {
        enqueueSnackbar(error + ': Forbidden', { variant: 'error' });
      }

      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (axiosResponse) => {
      return axiosResponse.data;
    },
    (error: AxiosError<IApiError>) => {
      if (error.response?.status === 403) {
        tratarErro(error.response?.data);
        return Promise.reject(error.response?.data);
      }

      if (error.response?.status === 401) {
        enqueueSnackbar('Sua sessão expirou. Efetue o login novamente.', {
          variant: 'error',
        });
        context.Logout();
        return Promise.reject(error.response?.data);
      }
      tratarErro(error.response?.data);
      return Promise.reject(error.response?.data);
    },
  );

  function post<T, R = ApiResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return api.post<T, R>(url, data, config);
  }

  function put<T, R = ApiResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return api.put<T, R>(url, data, config);
  }

  function get<T, R = ApiResponse<T>>(url: string, config?: AxiosRequestConfig) {
    return api.get<T, R>(url, config);
  }

  function getBlobFile<T, R = T>(url: string, config?: AxiosRequestConfig) {
    return api.get<T, R>(url, config);
  }

  function postBlobFile<T, R = T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return api.post<T, R>(url, data, config);
  }

  function remove<T, R = ApiResponse<T>>(url: string, config?: AxiosRequestConfig) {
    return api.delete<T, R>(url, config);
  }

  function getFile<T, R = T>(url: string, config?: AxiosRequestConfig) {
    return api.get<T, R>(url, config);
  }

  return {
    post,
    put,
    get,
    getFile,
    remove,
    getBlobFile,
    postBlobFile,
  };
}
